import React, { useEffect, useState,useRef } from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import FloatingWhatsAppButton from "./wsp";
import {
  Box,
  Typography,
  Paper,
  Button
} from "@mui/material";

import { useLocation,useNavigate  } from "react-router-dom";

const Verificacion = () => {

  const [datosConductor, setDatosConductor] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const datos = {
      nombre: queryParams.get("nombre"),
      fecha: queryParams.get("fecha"),
      hora: queryParams.get("hora"),
      lugar: queryParams.get("lugar"),
      marca: queryParams.get("marca"),
      modelo: queryParams.get("modelo"),
      color: queryParams.get("color"),
      patente: queryParams.get("patente"),
    };
    // if (!datos.nombre || !datos.fecha || !datos.hora || !datos.lugar || !datos.marca || !datos.modelo || !datos.color || !datos.patente) {
    //   navigate('/'); // Redirigir a Home si alguno de los datos es faltante
    // } else {
    //   setDatosConductor(datos);
    // }
  }, [location, navigate]);

  const { nombre,fecha, hora, lugar, marca,modelo,color ,patente } = datosConductor;

  const handleWhatsappClick = () => {
    window.open(`https://api.whatsapp.com/send?phone=56932603104&text=Me encuentro en el vehiculo,${marca} - ${patente} en ${fecha} - ${lugar} `, `_blank`);
  };

  return (
    <>
      <NavbarL />
      <FloatingWhatsAppButton />
      {/* {mostrarContenido ? (
      <Grid container >
        <Grid container justifyContent="center" alignItems="center" spacing={6}>
          <Grid item xs={10} md={3} >
          </Grid>
          <Grid item xs={10} md={6} >
            <Paper
              elevation={24}
              sx={{
                p: 3,
                borderRadius: "25px",
              }}
            >
              <Container maxWidth="xl">
              <Grid item container xs={12} md={12} spacing={1}>
                <Grid item container justifyContent="center" alignItems="center" xs={12} md={4} >
                  <input
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
                  <label htmlFor="image-upload">
                  <Button
                    sx={{
                      bgcolor: "#8c52fe",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "15px",
                      border: "1px solid #8c52fe",
                      transition: "background-color 0.3s ease-in-out",
                      "&:hover": {
                        bgcolor: "#7e57c2",
                      },
                    }}
                      component="span"
                      startIcon={''}
                      color="error"
                      size="medium"
                    >
                      <AddPhotoAlternateIcon/>
                    <Typography variant="body2" fontWeight="bold">ㅤSubir imagen</Typography>
                  </Button>
                      </label>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} md={4} >
                    <Typography variant="body2" fontWeight="bold">SCAN</Typography>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} md={4} >
                <IconButton
                  sx={{
                    bgcolor: "#8c52fe",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "15px",
                    border: "1px solid #8c52fe",
                    transition: "background-color 0.3s ease-in-out",
                    "&:hover": {
                      bgcolor: "#7e57c2",
                    },
                  }}
                  aria-label="Activar cámara"
                  color="error"
                  onClick={startVideo} // Aquí llamas a startVideo cuando se hace clic
                  size="medium"
                >
                      <AddAPhotoIcon/>
                    <Typography variant="body2" fontWeight="bold">ㅤEscanear QR</Typography>
                  </IconButton>
                </Grid>
              </Grid>
              </Container>
              <br/>
              {!showCamera && imageUploaded && (
                        <>
                          <Paper
                            sx={{
                              margin: 'auto',
                              width: 345,
                              height: 270,
                              backgroundColor: '#white',
                              border: '5px solid black',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={URL.createObjectURL(image)}
                              alt="imagen"
                              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                            />
                          </Paper>
                          <br />
                          <Grid item container xs={12} md={12} spacing={1}>
                            <Grid item container justifyContent="center" alignItems="center" xs={12} md={4} >
                            <Button
                              variant="contained"
                              color="error"
                              component="span"
                              onClick={() => setImage(null)}
                            >
                              Quitar Imagen
                            </Button>
                            </Grid>
                            <Grid item container justifyContent="center" alignItems="center" xs={12} md={4}></Grid>
                            <Grid item container justifyContent="center" alignItems="center" xs={12} md={4} >
                              <Button
                              variant="contained"
                              color="primary"
                              onClick={subirImagen}
                            >
                              Siguiente
                            </Button>
                            </Grid>
                            </Grid>
                          <p />
                        </>
              )}
              {showCamera && !imageUploaded && (
                <video ref={videoRef} style={{ width: '100%', borderRadius: 20 }} />
              )}
                  
            </Paper>
          </Grid>
          <Grid item xs={10} md={3} >
          </Grid>
        </Grid>
      </Grid>
      ) : (
        carga // Muestra la animación de carga si mostrarContenido es false
      )}
      <style>{`
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
      `}
      </style> */}
      <br/>
      <br/>
      <Box sx={{ margin: 'auto', maxWidth: 500 }}>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Typography variant="h4" component="h1" sx={{ textAlign: 'center', marginBottom: 2 }}>
          Verificación aprobada
        </Typography>
        <Typography variant="h5" gutterBottom>
          Datos conductor
        </Typography>
        <Typography variant="body1" gutterBottom>
          Name: {nombre}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Fecha: {fecha}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Hora: {hora}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Lugar de recogida: {lugar}
        </Typography>
        <Typography variant="h5" gutterBottom>
          Datos del vehículo
        </Typography>
        <Typography variant="body1" gutterBottom>
          Placa: {patente}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Marca: {marca}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Modelo: {modelo}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Color: {color}
        </Typography>
        <Button variant="contained" color="primary" sx={{ width: '100%', marginTop: 3 }} onClick={handleWhatsappClick}>
          ¡Estoy arriba del vehículo!
        </Button>
      </Paper>
    </Box>
      <br/>
      <br/>
      <br/>
      <br/>
      <TerminoPag />
    </>
  )
}

export default Verificacion;
