import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, TextField, Button, AppBar, Snackbar, Alert } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ObtenerImagenPersonalAcreditado, VerificarPersonalAcreditado } from "../../APIS/api";
import Image from "mui-image";
import Logo from '../Imagenes/logo_morado_texto_al_lado.png';
import fondo from "./../Imagenes/Santiago.jpg";
import { enviarCorreoComentario } from "../../APIS/apiCorreo";

const PersonalAcreditado = () => {
  const [datosConductor, setDatosConductor] = useState({});
  const [esAcreditado, setEsAcreditado] = useState(null);
  const [comentarios, setComentarios] = useState("");
  const [urlImagen, setUrlImagen] = useState("");
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const datos = {
      nombre: queryParams.get("nombre"),
      cargo: queryParams.get("cargo"),
      dni: queryParams.get("dni"),
    };
    if (!datos.nombre || !datos.cargo || !datos.dni) {
      navigate('/');
    } else {
      setDatosConductor(datos);
      verificarYRedirigir(datos);
    }
  }, [location, navigate]);

  const verificarYRedirigir = async (datos) => {
    setLoading(true);
    const { nombre } = datos;
    const resultado = await VerificarPersonalAcreditado(nombre);
    const url = await ObtenerImagenPersonalAcreditado(nombre);
    setEsAcreditado(resultado);
    setUrlImagen(url);
    setLoading(false);
  };

  const { nombre, cargo, dni } = datosConductor;

  const handleEnviar = async () => {
    if (!comentarios) {
      setMostrarAlerta(true);
      return;
    }
    if (comentarios) {
      const para = 'reservas@transitchile.cl, vicente@transitglobalgroup.com, matias@transitglobalgroup.com';
      const asunto = 'Comentario del personal acreditado';
      const mensaje = `
        <p><strong>Nombre:</strong> ${nombre}</p>
        <p><strong>Cargo:</strong> ${cargo}</p>
        <p><strong>DNI:</strong> ${dni}</p>
        <p><strong>Comentarios:</strong> ${comentarios}</p>
      `;
      await enviarCorreoComentario(para, asunto, mensaje);
      setMostrarAlertaExito(true);
      setComentarios("");
    }
  }

  return (
    <>
      <AppBar position="static" sx={{ bgcolor: '#8c52fe', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ width: '100%', maxWidth: '600px', textAlign: 'center', padding: '10px' }}>
          <Image
            src={Logo}
            alt="Logo"
            style={{
              width: '100%',
              maxWidth: '250px',
              height: 'auto',
            }}
          />
        </Box>
      </AppBar>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '92.4vh',
          background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${fondo}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left top',
          minHeight: '20vh',
        }}
      >
        <Box
          component={Paper}
          sx={{
            px: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            borderRadius: 2,
            width: '300px',
            textAlign: 'center',
          }}
        >
          {loading ? (
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Verificando...
            </Typography>
          ) : esAcreditado === true ? (
            <>
              <CheckCircleIcon sx={{ fontSize: 120, color: 'green', mb: 2 }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                PERSONAL AUTORIZADO
              </Typography>
              <img src={urlImagen} alt={nombre} style={{ width: '50%', borderRadius: '10px', marginBottom: '20px' }} />
            </>
          ) : esAcreditado === false ? (
            <>

              <CancelIcon sx={{ fontSize: 120, color: 'red', mb: 2 }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'red' }}>
                PERSONAL NO AUTORIZADO
              </Typography>
            </>
          ) : (
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              No se pudo verificar
            </Typography>
          )}

          <Typography variant="body1" >
            <strong>Nombre:</strong> {nombre}
          </Typography>
          <Typography variant="body1">
            <strong>Cargo:</strong> {cargo}
          </Typography>
          <Typography variant="body1">
            <strong>DNI:</strong> {dni}
          </Typography>
          <TextField
            fullWidth
            autoFocus
            color="primary"
            margin="normal"
            variant="outlined"
            label="Ingrese sus comentarios"
            type="Comentarios"
            name="Comentarios"
            multiline
            rows={4}
            value={comentarios}
            onChange={(e) => setComentarios(e.target.value)}
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleEnviar}
            sx={{ bgcolor: '#8c52fe', color: 'white', fontWeight: 'bold', mt: 2 }}
          >
            Enviar
          </Button>
          <Typography variant="overline" color="initial">
            {" "}Sistema de verificación T&Transit{" "}
          </Typography>
        </Box>
      </div>
      <Snackbar
        open={mostrarAlerta}
        autoHideDuration={3000}
        onClose={() => setMostrarAlerta(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          Por favor, ingrese sus comentarios.
        </Alert>
      </Snackbar>
      <Snackbar
        open={mostrarAlertaExito}
        autoHideDuration={3000}
        onClose={() => setMostrarAlertaExito(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          ¡Enviado con exito!
        </Alert>
      </Snackbar>
    </>
  );
}

export default PersonalAcreditado;