
import React from "react";
import Insta from "../Imagenes/Correos/Insta.png";
import Logo from "../Imagenes/Correos/Logo.png";
import mail from "../Imagenes/Correos/mail.png";
import Order_fill from "../Imagenes/Correos/Order_fill.png";
import Pin_fill from "../Imagenes/Correos/Pin_fill.png";
import Sedan from "../Imagenes/Correos/Sedan.png";
import SUV from "../Imagenes/Correos/SUV.png";
import User_fill from "../Imagenes/Correos/User_fill.png";
import VAN from "../Imagenes/Correos/VAN.png";
import wsp from "../Imagenes/Correos/wsp.png";

const Qwerty = () => {

  return (
    <>
      <h1>test</h1>
      <img src={Insta} alt="Insta" />
      <img src={Logo} alt="Logo" />
      <img src={mail} alt="mail" />
      <img src={Order_fill} alt="Order_fill" />
      <img src={Pin_fill} alt="Pin_fill" />
      <img src={Sedan} alt="Sedan" />
      <img src={SUV} alt="SUV" />
      <img src={User_fill} alt="User_fill" />
      <img src={VAN} alt="VAN" />
      <img src={wsp} alt="wsp" />
    </>
  );
}


export default Qwerty;
