import React, { useEffect, useState, useRef } from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
    Toolbar,
    Grid,
    Box,
    Card,
    IconButton,
    Typography,
    Button,
    Container,
    CardActionArea,
    CardMedia,
    CardContent,
    CardActions,
    Paper,
    TextField,
    Alert,
    Snackbar
} from "@mui/material";
import fondo from "./../Imagenes/Santiago.jpg";
import SUV from "./../Imagenes/Vehiculos/SUV.png";
import VAN from "./../Imagenes/Vehiculos/VAN.png";
import Minibus from "./../Imagenes/Vehiculos/Minibus.png";
import BUS from "./../Imagenes/Vehiculos/BUS.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingWhatsAppButton from "./wsp";
import { useNavigate } from "react-router-dom";

import fondoo from "./../Imagenes/Fondoo.jpg";
import tour1 from "./../Imagenes/Tour1.png";
import tour2 from "./../Imagenes/Tour2.png";
import tour3 from "./../Imagenes/Tour3.png";

import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const Contacto = ({ handleData }) => {
    const [fechaRecogida, setFechaRecogida] = useState('');
    const [origen, setOrigen] = useState('');
    const [destino, setDestino] = useState('');
    const [pax, setPax] = useState('');
    const [horaRecogida, setHoraRecogida] = useState('');
    const navigate = useNavigate();

    const [nombreEmpresa, setNombreEmpresa] = useState('');
    const [giroEmpresa, setGiroEmpresa] = useState('');
    const [rutEmpresa, setRutEmpresa] = useState('');
    const [nombreContacto, setNombreContacto] = useState('');
    const [telefonoContacto, setTelefonoContacto] = useState('');

    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

    const mostrarAlertaFaltaInformacion = () => {
        setMostrarAlerta(true);
    };

    const mostrarAlertaGuardadoExito = () => {
        setMostrarAlertaExito(true);
    };


    const handleContinuar = () => {
        navigate("/Reservas");
        handleData({ fechaRecogida, origen, destino, pax, horaRecogida });
    };


    const handleEnviar = async () => {
        if (
            nombreEmpresa &&
            giroEmpresa &&
            rutEmpresa &&
            nombreContacto &&
            telefonoContacto
        ) {
            // await GuardarDatosPaginaWeb(id, Datos);
            mostrarAlertaGuardadoExito();
            setNombreEmpresa("");
            setGiroEmpresa("");
            setRutEmpresa("");
            setNombreContacto("");
            setTelefonoContacto("");
            return true;
        } else {
            mostrarAlertaFaltaInformacion();
            return false;
        }
    };

    const containerStyle = {
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${fondo}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left top',
        minHeight: '20vh',
    };

    const containerStylee = {
        background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('${fondoo}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '20vh',
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const ViajesByItem = (item) => {
        switch (item) {
            case 1:
                return { title: "Clásica de Viña", info: "Test", image: tour1, price: "10.000" };
            case 2:
                return { title: "Premium de Viña", info: "Test", image: tour2, price: "15.000" };
            case 3:
                return { title: "Exclusiva de Viña", info: "Test", image: tour3, price: "20.000" };
            case 4:
                return { title: "Test", info: "Test", image: tour1 };
            case 5:
                return { title: "Test", info: "Test", image: tour1 };
            default:
                return {};
        }
    };

    const getInfoByItem = (item) => {
        switch (item) {
            case 1:
                return { title: "SUV", passengers: 4, image: SUV, };
            case 2:
                return { title: "VAN", passengers: 6, image: VAN };
            case 3:
                return { title: "MINI BUS", passengers: 12, image: Minibus };
            case 4:
                return { title: "BUS", passengers: 40, image: BUS };
            default:
                return {};
        }
    };

    const settingsProv = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const getProveedores = (item) => {
        switch (item) {
            case 1:
                return { title: "Alianza", passengers: 1, image: SUV };
            case 2:
                return { title: "Alianza", passengers: 2, image: SUV };
            case 3:
                return { title: "Alianza", passengers: 3, image: SUV };
            case 4:
                return { title: "Alianza", passengers: 4, image: SUV };
            case 5:
                return { title: "Alianza", passengers: 5, image: SUV };
            case 6:
                return { title: "Alianza", passengers: 6, image: SUV };
            case 7:
                return { title: "Alianza", passengers: 6, image: SUV };
            case 8:
                return { title: "Alianza", passengers: 7, image: SUV };
            default:
                return {};
        }
    };

    const [programarViajeActivo, setProgramarViajeActivo] = useState(true);
    const [anularViaje, setanularViaje] = useState(false);

    const handleToggleProgramarViaje = () => {
        setProgramarViajeActivo(true);
        setanularViaje(false);
        setAnularViaje(false);
        setPrivado(false);
        setEventos(false);
    };

    const handleToggleAnulacionViaje = () => {
        setanularViaje(true);
        setProgramarViajeActivo(false);
        setAnularViaje(false);
        setPrivado(false);
        setEventos(false);
    };

    const [aeropuerto, setAnularViaje] = useState(false);
    const [privado, setPrivado] = useState(false);
    const [eventos, setEventos] = useState(false);

    const handleToggleAeropuerto = () => {
        setAnularViaje(true);
        setPrivado(false);
        setEventos(false);
        setProgramarViajeActivo(false);
        setanularViaje(false);
    };

    const handleTogglePrivado = () => {
        setAnularViaje(false);
        setPrivado(true);
        setEventos(false);
        setProgramarViajeActivo(false);
        setanularViaje(false);
    };

    const handleToggleEventos = () => {
        setAnularViaje(false);
        setPrivado(false);
        setEventos(true);
        setProgramarViajeActivo(false);
        setanularViaje(false);
    };

    const handlePaxChange = (e) => {
        const newValue = e.target.value;
        // Permitir solo números entre 1 y 7
        if (newValue === "" || (parseInt(newValue) >= 1 && parseInt(newValue) <= 7)) {
            setPax(newValue);
        }
    };



    return (
        <>
            <NavbarL />
            <FloatingWhatsAppButton />
            <Box >
                <Box style={containerStylee} >
                    <br />
                    <br />
                    <br />
                    <Container maxWidth="xl">
                        <Grid container xs={12} md={12}>
                            <Grid item container justifyContent="center" alignItems="center" spacing={4} >
                                <Grid item justifyContent="center" alignItems="center" container xs={10} md={12}>
                                    <Paper
                                        elevation={24}
                                        sx={{
                                            borderRadius: 5,
                                            bgcolor: (theme) => theme.palette.background.default,
                                            p: 3,
                                            opacity: 0.7, // Ajusta el valor de opacidad según tus necesidades (entre 0 y 1)
                                        }}
                                    >
                                        <Typography
                                            color="#8c52fe"
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '40px',
                                            }}
                                        >
                                            NUESTRAS ALIANZAS
                                        </Typography>
                                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}>
                                            <Typography
                                                color="#8c52fe"
                                                fontWeight="bold"
                                                sx={{
                                                    fontSize: '25px',
                                                }}
                                            >
                                                CON EMPRESAS
                                            </Typography>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    <br />
                    <br />
                    <br />
                    <Container maxWidth="xl">
                        <Grid container >
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid
                                    item
                                    xs={4}
                                    md={4}
                                    container
                                >
                                </Grid>
                                <Grid item xs={10} md={4} >
                                    <Card sx={{
                                        maxWidth: "auto",
                                        height: "auto",
                                        borderRadius: '20px',
                                        padding: 1,
                                        border: '3px solid #8c52fe',
                                    }}>
                                        <p />
                                        <Typography variant="h6" fontWeight="bold" color="#8c52fe" textAlign="center">
                                            Ingresa tus datos
                                        </Typography>
                                        <CardContent>
                                            <Grid container xs={12} md={12} >
                                                <Grid item container justifyContent="center" alignItems="center" spacing={2} >
                                                    <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                        <TextField
                                                            id={`outlined-required-`}
                                                            label="Nombre Empresa"
                                                            placeholder="Transit SPA"
                                                            type="text"
                                                            sx={{ width: "100%" }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={nombreEmpresa}
                                                            onChange={(e) => setNombreEmpresa(e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                        <TextField
                                                            id={`outlined-required-`}
                                                            label="Giro Empresa"
                                                            placeholder="Transporte"
                                                            type="text"
                                                            sx={{ width: "100%" }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={giroEmpresa}
                                                            onChange={(e) => setGiroEmpresa(e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                        <TextField
                                                            id={`outlined-required-`}
                                                            label="Rut Empresa"
                                                            placeholder="76.123.456-7"
                                                            type="float"
                                                            sx={{ width: "100%" }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={rutEmpresa}
                                                            onChange={(e) => setRutEmpresa(e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                        <TextField
                                                            id={`outlined-required-`}
                                                            label="Nombre del contacto"
                                                            placeholder="Nombre Apellido"
                                                            type="text"
                                                            sx={{ width: "100%" }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={nombreContacto}
                                                            onChange={(e) => setNombreContacto(e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                                        <TextField
                                                            id={`outlined-required-`}
                                                            label="Teléfono del contacto"
                                                            placeholder="+569 12345678"
                                                            type="number"
                                                            sx={{ width: "100%" }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={telefonoContacto}
                                                            onChange={(e) => setTelefonoContacto(e.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Button
                                                size="medium"
                                                color="primary"
                                                sx={{
                                                    width: '100%',
                                                    bgcolor: '#8c52fe',
                                                    color: 'white',
                                                    padding: '10px',
                                                }}
                                                onClick={handleEnviar}
                                            >
                                                Enviar
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    md={4}
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    <br />
                    <br />
                    <br /> 
                </Box>
                <br />
            </Box>
            <TerminoPag />
            <Snackbar
                open={mostrarAlerta}
                autoHideDuration={3000}
                onClose={() => setMostrarAlerta(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
                    Por favor, complete todos los campos obligatorios.
                </Alert>
            </Snackbar>
            <Snackbar
                open={mostrarAlertaExito}
                autoHideDuration={3000}
                onClose={() => setMostrarAlertaExito(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
                    ¡Enviado con éxito!
                </Alert>
            </Snackbar>
        </>
    )
}

export default Contacto