import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme"; 

const root = createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  </ThemeProvider>
);

reportWebVitals();