import React, { useEffect, useState,useRef} from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
  Toolbar,
  Grid,
  Box,
  Card,
  IconButton,
  Typography,
  Button,
  Container,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Paper,
  TextField,
} from "@mui/material";
import fondo from "./../Imagenes/Santiago.jpg";
import Vision from "./../Imagenes/Vision.jpg";
import Quienes from "./../Imagenes/QuienesSomos.jpg";
import fondoo from "./../Imagenes/Fondoo.jpg";


import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingWhatsAppButton from "./wsp";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const QuienesSomos = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const containerStyle = {
      background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${fondo}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: '20vh',
    };

    const containerStylee = {
        background: `url('${fondoo}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '40vh',
      };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return(
        <>
            <NavbarL/>
            <FloatingWhatsAppButton/>
            <Box>
            <Box style={containerStylee} >
            <br/>
            <br/>
            <br/>
            <Grid container xs={12} md={12}>
                <Grid item container justifyContent="center" alignItems="center" >
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={1}></Grid>
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={5} >
                        <CardMedia component="img" height="350px" image={Quienes} sx={{  borderRadius: '45px'}} />
                    </Grid>
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={5} >
                    <Paper 
                        elevation={24}
                        sx={{
                            borderRadius: 12,
                            bgcolor: (theme) => theme.palette.background.default,
                            width: '350px',
                            height: isMobile ? '375px' : '300px',
                            p: 3,
                            opacity: 0.8, // Ajusta el valor de opacidad según tus necesidades (entre 0 y 1)
                        }}
                    >
                        <Grid justifyContent="left" alignItems="left" container xs={12} md={12} spacing={2} >
                            <Grid item>
                            <Typography
                                color="#8c52fe"
                                fontWeight="bold"
                                sx={{
                                fontSize: '25px',
                                }}
                            >
                            ¿Quiénes Somos?
                            </Typography>
                            </Grid>
                            <Grid item>
                            <ContactSupportIcon sx={{
                                bgcolor: "#8c52fe",
                                color: "white",
                                borderRadius: "100%", 
                                border: "7px solid #8c52fe",
                                fontSize: "25px",
                                }} 
                                />
                            </Grid>
                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                        <Typography
                            fontWeight="bold"
                            sx={{
                            fontSize: '15px',
                            textAlign: 'justify',
                            }}
                        >
                        Somos una empresa de trasporte joven del Siglo XXI, rápida y tecnológica.
                        Especializados en el transporte de pasajeros hacia y desde el aeropuerto, o bien, desde un punto A hacia un punto B. Siempre
                        ofreciendo un servicio ágil, único y de calidad. Además de contar con una extensa flota a lo largo de toda la Región
                        Metropolitana e Internacionales para los requerimientos de cada uno de nuestros clientes, tanto empresas como personas particulares.
                        </Typography>
                        </Grid>
                        </Paper>
                    </Grid>
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={1}></Grid>
                    </Grid>
            </Grid>
            <Grid container xs={12} md={12}>
                <br/>
                <br/>
            </Grid>
            <Grid container xs={12} md={12} spacing={0}>
        <Grid item container justifyContent="center" alignItems="center">
            <Grid item justifyContent="center" alignItems="center" container xs={10} md={1}></Grid>
                <Grid item justifyContent="center" alignItems="center" container xs={10} md={5}>
                            <CardMedia component="img" height="500px" image={Vision} sx={{  borderRadius: '45px'}} />
                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={10} md={5} >
                            <Paper 
                            elevation={24}
                            sx={{
                                borderRadius: 12,
                                bgcolor: (theme) => theme.palette.background.default,
                                width: '350px',
                                height: isMobile ? '255px' : '205px',
                                p: 3,
                                opacity: 0.8,
                            }}
                        >
                            <Grid justifyContent="left" alignItems="left" container xs={12} md={12} spacing={2} >
                                <Grid item>
                                <Typography
                                    color="#8c52fe"
                                    fontWeight="bold"
                                    sx={{
                                    fontSize: '25px',
                                    }}
                                >
                                Nuestra Misión
                                </Typography>
                                </Grid>
                                <Grid item>
                                <EmojiObjectsOutlinedIcon sx={{
                                    bgcolor: "#8c52fe",
                                    color: "white",
                                    borderRadius: "100%", 
                                    border: "7px solid #8c52fe",
                                    fontSize: "25px",
                                    }} 
                                    />
                                </Grid>
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                            <Typography
                                fontWeight="bold"
                                sx={{
                                fontSize: '15px',
                                textAlign: 'justify',
                                }}
                            >
                            Ser una empresa completamente íntegra, abarcando distintos segmentos de mercado, ofreciendo servicios de viajes
                            especiales, viajes para empresas privadas, servicios para agencias y transporte de personal esencial, logrando la satisfacción y
                            fidelidad máxima de cada uno de nuestros clientes.
                            </Typography>
                            </Grid>
                            <br/>
                            <br/>
                            </Paper>
                            <br/>
                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} > <br/></Grid>
                            <br/>
                            <Paper 
                            elevation={24}
                            sx={{
                                borderRadius: 12,
                                bgcolor: (theme) => theme.palette.background.default,
                                width: '350px',
                                height: '200px',
                                p: 3,
                                opacity: 0.8,
                            }}
                        >
                            <Grid justifyContent="left" alignItems="left" container xs={12} md={12} spacing={2} >
                                <Grid item>
                                <Typography
                                    color="#8c52fe"
                                    fontWeight="bold"
                                    sx={{
                                    fontSize: '25px',
                                    }}
                                >
                                Nuestra Visión
                                </Typography>
                                </Grid>
                                <Grid item>
                                <Diversity3OutlinedIcon sx={{
                                    bgcolor: "#8c52fe",
                                    color: "white",
                                    borderRadius: "100%", 
                                    border: "7px solid #8c52fe",
                                    fontSize: "25px",
                                    }} 
                                    />
                                </Grid>
                            </Grid>
                            <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                            <Typography
                                fontWeight="bold"
                                sx={{
                                fontSize: '15px',
                                textAlign: 'justify',
                                }}
                            >
                            Nuestra visión es ser líderes dentro del mercado de transporte, abarcando cada vez más ciudades de Chile. 
                            Logrando reconocimiento y distinción como una empresa juvenil con los servicios más personalizados y 
                            exclusivos del mercado.
                            </Typography>
                            </Grid>
                            <br/>
                            <br/>
                            <br/>
                            </Paper>
                            <br/>
                        </Grid>
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={1}></Grid>
                </Grid>
            </Grid>
            <br/><br/><br/>
            <Grid container xs={12} md={12}>
                <Grid item container justifyContent="center" alignItems="center" spacing={4} >
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={10}>
                    <Paper 
                            elevation={24}
                            sx={{
                                borderRadius: 12,
                                bgcolor: (theme) => theme.palette.background.default,
                                width: '90%',
                                height: '90%',
                                p: 3,
                                opacity: 0.7,
                            }}
                        >
                    <Typography
                        color="black"
                        fontWeight="bold"
                        sx={{
                        fontSize: '30px',
                        textAlign: 'center',
                        }}
                    >
                    El final de nuestro viaje es solo el inicio de nuestra relación
                    </Typography>
                </Paper>
                </Grid>
                </Grid>
                </Grid>
                        <br/>
                        <br/>
                        <br/>
                    </Box>
                </Box>
            <TerminoPag/>
        </>
    )
}

export default QuienesSomos
