import React, { useState, useEffect } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './float.css';

const FloatingWhatsAppButton = () => {
  const [showDefaultMessage, setShowDefaultMessage] = useState(true);

  const handleWhatsappClick = () => {
    // Reemplaza el número con tu número de WhatsApp
    window.open('https://api.whatsapp.com/send?phone=56932603104&text=Estimad@s, necesito generar una reserva', '_blank');
  };

  useEffect(() => {
    const showMessage = () => {
      setShowDefaultMessage(true);
      setTimeout(() => {
        setShowDefaultMessage(false);
      }, 10000);
    };
  
    const intervalId = setInterval(() => {
      showMessage();
    }, 30000); 
  
    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <div className="floating-whatsapp-container">
      {showDefaultMessage && (
        <div className="default-whatsapp" onClick={handleWhatsappClick}>
          <div className="default-message">
            ¡Hola! ¿En qué podemos ayudarte?
          </div>
        </div>
      )}
      <div className="floating-whatsapp-button" onClick={handleWhatsappClick}>
        <WhatsAppIcon sx={{ fontSize: '40px', color: 'white' }} />
      </div>
    </div>
  );
};

export default FloatingWhatsAppButton;
