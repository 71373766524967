export const enviarCorreo = async (para, asunto, mensaje) => {
  try {
    const apiUrl = `https://us-central1-transit-399da.cloudfunctions.net/enviarCorreo`;
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
      },
      body: JSON.stringify({ para, asunto, mensaje })
    });

    if (response.ok) {
      console.log('Correo enviado exitosamente');
    } else {
      console.log('Error al enviar el correo');
    }
  } catch (error) {
    console.error('Error al enviar el correo:', error);
  }
};

export const enviarCorreoComentario = async (para, asunto, mensaje) => {
  try {
    const apiUrl = `https://us-central1-transit-399da.cloudfunctions.net/enviarCorreo`;
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
      },
      body: JSON.stringify({ para, asunto, mensaje })
    });
    if (response.ok) {
      console.log('Correo enviado exitosamente');
    } else {
      console.log('Error al enviar el correo');
    }
  } catch (error) {
    console.error('Error al enviar el correo:', error);
  }
};

