import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Proceso = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const exito = location.state?.exito;

  useEffect(() => {
    // Este efecto se ejecutará al montar el componente.
    // Se agrega un retraso de 2 segundos antes de verificar el estado y posiblemente redirigir.
    const timer = setTimeout(() => {
        if (exito === undefined || exito === false) {
            navigate('/Confirmacion');
        }
    }, 3000); // Espera 2 segundos

    // Limpieza del temporizador cuando el componente se desmonte o antes de volver a ejecutar el efecto.
    return () => clearTimeout(timer);
  }, [exito, navigate]);

  return (
      <>
      <style>{`
          @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
          }
      `}</style>
      <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
      }}>
          <div style={{
              border: '16px solid #f3f3f3', /* Color de fondo */
              borderTop: '16px solid #3498db', /* Color del borde */
              borderRadius: '50%',
              width: '120px',
              height: '120px',
              animation: 'spin 2s linear infinite',
          }}>
          </div>
      </div>
      </>
  );
}

export default Proceso;