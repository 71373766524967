import React, { useEffect, useState, useRef } from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
    Toolbar,
    Grid,
    Box,
    Card,
    IconButton,
    Typography,
    Button,
    Container,
    CardActionArea,
    CardMedia,
    CardContent,
    CardActions,
    Paper,
    TextField,
} from "@mui/material";
import fondo from "./../Imagenes/Santiago.jpg";
import Vision from "./../Imagenes/Vision.jpg";
import Quienes from "./../Imagenes/QuienesSomos.jpg";
import fondoo from "./../Imagenes/Fondoo.jpg";

import Traslado_Aeropuerto from "./../Imagenes/Servicios/Traslado-al-aeropuerto.webp";
import Traslado_a_b from "./../Imagenes/Servicios/Traslado-a-b.webp"
import Traslado_personal from "./../Imagenes/Servicios/Traslado-personal.webp"
import Traslado_tour from "./../Imagenes/Servicios/Traslado-tour.webp"


import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingWhatsAppButton from "./wsp";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Servicios = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const containerStyle = {
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${fondo}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '20vh',
    };

    const containerStylee = {
        background: `url('${fondoo}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '40vh',
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <NavbarL />
            <FloatingWhatsAppButton />
            <br />
            <br />
            <Grid container xs={12} md={12}>
                <Grid item container justifyContent="center" alignItems="center" spacing={4} >
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={10}>
                        <Typography
                            color="#8c52fe"
                            fontWeight="bold"
                            sx={{
                                fontSize: '35px',
                            }}
                        >
                            Servicios que ofrecemos a nuestros clientes
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid container xs={12} md={12}>
                <Grid item container justifyContent="center" alignItems="center" spacing={3} >
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={1.5}></Grid>
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={3} >
                        <FlightTakeoffIcon sx={{
                            bgcolor: "#8c52fe",
                            color: "white",
                            fontWeight: "bold",
                            borderRadius: "100%",
                            border: "7px solid #8c52fe",
                            fontSize: "30px",
                        }}
                        />
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}> <br /> </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}>
                            <Typography
                                color="#8c52fe"
                                fontWeight="bold"
                                sx={{
                                    fontSize: '15px',
                                }}
                            >
                                TRASLADO AL AEROPUERTO
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="center">
                                Puntualidad y seriedad es lo que nos caracteriza, es por la razón que somos líderes en transporte
                                de pasajeros al aeropuerto de Santiago de manera segura.
                            </Typography>
                            <br />
                            <br />
                        </Grid>
                        <FlightTakeoffIcon sx={{
                            bgcolor: "#8c52fe",
                            color: "white",
                            fontWeight: "bold",
                            borderRadius: "100%",
                            border: "7px solid #8c52fe",
                            fontSize: "30px",
                        }}
                        />
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}> <br /> </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}>
                            <Typography
                                color="#8c52fe"
                                fontWeight="bold"
                                sx={{
                                    fontSize: '15px',
                                }}
                            >
                                DESTINOS IMPERDIBLES
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="center">
                                Contamos con una amplia gama de destinos turísticos, los cuales te permitirán conocer nuestro
                                hermoso país y vivir una experiencia realmente inolvidable.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item justifyContent="center" alignItems="center" container xs={11} md={3} >
                        <CardMedia component="img" height="400px" width="100%" image={Quienes} sx={{ borderRadius: '30px' }} />
                    </Grid>
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={3} >
                        <FlightTakeoffIcon sx={{
                            bgcolor: "#8c52fe",
                            color: "white",
                            fontWeight: "bold",
                            borderRadius: "100%",
                            border: "7px solid #8c52fe",
                            fontSize: "30px",
                        }}
                        />
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}> <br /> </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}>
                            <Typography
                                color="#8c52fe"
                                fontWeight="bold"
                                sx={{
                                    fontSize: '15px',
                                }}
                            >
                                VIAJES FUERA DE SANTIAGO
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="center">.
                                Nuestros choferes son profesionales y tienen una amplia experiencia en viajes tanto dentro como fuera de Santiago,
                                lo que te permitirá llegar seguro y puntual a tu destino.
                            </Typography>
                            <br />
                            <br />
                        </Grid>
                        <FlightTakeoffIcon sx={{
                            bgcolor: "#8c52fe",
                            color: "white",
                            fontWeight: "bold",
                            borderRadius: "100%",
                            border: "7px solid #8c52fe",
                            fontSize: "30px",
                        }}
                        />
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}> <br /> </Grid>
                        <Grid item justifyContent="center" alignItems="center" container xs={12} md={12}>
                            <Typography
                                color="#8c52fe"
                                fontWeight="bold"
                                sx={{
                                    fontSize: '15px',
                                }}
                            >
                                TRANSPORTE CORPORATIVO
                            </Typography>
                            <Typography variant="body2" color="text.secondary" textAlign="center">
                                Contamos con grandes clientes y la experiencia necesaria para transportar a tu personal,
                                lo que te permitirá llegar a tu destino con la seguridad que necesitas.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item justifyContent="center" alignItems="center" container xs={10} md={1.5}></Grid>
                </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <Box style={containerStylee} >
                <br />
                <br />
                <br />
                <Grid container xs={12} md={12}>
                    <Grid item container justifyContent="center" alignItems="center" spacing={4} >
                        <Grid item justifyContent="right" alignItems="right" container xs={10} md={6}>
                            <Paper
                                elevation={24}
                                sx={{
                                    borderRadius: 12,
                                    bgcolor: 'background.default',
                                    width: '500px',
                                    height: isMobile ? '630px' : '600px',
                                    p: 3,
                                }}
                            >
                                <CardMedia component="img" height="300px" image={Traslado_Aeropuerto} sx={{ borderRadius: '30px' }} />
                                <br />
                                <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} spacing={1} >
                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            color="#8c52fe"
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '30px',
                                            }}
                                        >
                                            TRASLADO AL AEROPUERTO
                                        </Typography>
                                    </Grid>

                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '15px',
                                            }}
                                        >
                                            Contamos con personal las 24 horas del día, siempre
                                            dispuestos a transportar a nuestros clientes hacia el aeropuerto.
                                        </Typography>
                                        <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} ></Grid>
                                    </Grid>
                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '15px',
                                            }}
                                        >
                                            - Máximo 2 maletas por persona.
                                        </Typography>
                                    </Grid>
                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '15px',
                                            }}
                                        >
                                            - $7.500 por dirección extra.
                                        </Typography>
                                    </Grid>
                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '15px',
                                            }}
                                        >
                                            - Contamos con sillas de bebes, para los vehiculos.
                                        </Typography>
                                    </Grid>
                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '15px',
                                            }}
                                        >
                                            - Se permiten mascotas en su jaula.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item justifyContent="left" alignItems="left" container xs={10} md={6} >
                            <Paper
                                elevation={24}
                                sx={{
                                    borderRadius: 12,
                                    bgcolor: 'background.default',
                                    width: '500px',
                                    height: '600px',
                                    p: 3,
                                }}
                            >
                                <CardMedia component="img" height="300px" image={Traslado_a_b} sx={{ borderRadius: '30px' }} />
                                <br />
                                <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} spacing={1} >
                                    <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} >
                                        <Typography
                                            color="#8c52fe"
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '30px',
                                            }}
                                        >
                                            TRASLADO DE UN PUNTO "A" HACIA UN PUNTO "B"
                                        </Typography>
                                    </Grid>
                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '15px',
                                            }}
                                        >
                                            En T&Transit realizamos viajes especiales para ti, te
                                            llevamos a cualquier destino dentro de la
                                            Región Metropolitana, ya sea, un matrimonio,
                                            evento especial, a tu trabajo, etc.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item justifyContent="right" alignItems="right" container xs={10} md={6} >
                            <Paper
                                elevation={24}
                                sx={{
                                    borderRadius: 12,
                                    bgcolor: 'background.default',
                                    width: '500px',
                                    height: '600px',
                                    p: 3,
                                }}
                            >
                                <CardMedia component="img" height="300px" image={Traslado_personal} sx={{ borderRadius: '30px' }} />
                                <br />
                                <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} spacing={1} >
                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            color="#8c52fe"
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '30px',
                                            }}
                                        >
                                            TRANSPORTE DE PERSONAL ESENCIAL
                                        </Typography>
                                    </Grid>
                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '15px',
                                            }}
                                        >
                                            Nuestros conductores están debidamente formados y poseen la experiencia requerida
                                            para el transporte de tu equipo, asegurando su llegada segura y a tiempo al trabajo.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item justifyContent="left" alignItems="left" container xs={10} md={6} >
                            <Paper
                                elevation={24}
                                sx={{
                                    borderRadius: 12,
                                    bgcolor: 'background.default',
                                    width: '500px',
                                    height: '600px',
                                    p: 3,
                                }}
                            >
                                <CardMedia component="img" height="300px" image={Traslado_tour} sx={{ borderRadius: '30px' }} />
                                <br />
                                <Grid item justifyContent="center" alignItems="center" container xs={12} md={12} spacing={1} >
                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            color="#8c52fe"
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '30px',
                                            }}
                                        >
                                            TOURS IMPERDIBLES
                                        </Typography>
                                    </Grid>

                                    <Grid item justifyContent="left" alignItems="left" container xs={12} md={12} >
                                        <Typography
                                            fontWeight="bold"
                                            sx={{
                                                fontSize: '15px',
                                            }}
                                        >
                                            Actualmente contamos con diversos
                                            convenios los cuales te permitirán conocer
                                            nuestro hermoso país y vivir una experiencia
                                            realmente inolvidable.
                                        </Typography>
                                        <br />
                                        <Grid item justifyContent="right" alignItems="right" container xs={12} md={12} > <br /> </Grid>
                                        <Grid item justifyContent="right" alignItems="right" container xs={12} md={12} >
                                            <br />
                                            <br />
                                            <Button variant="contained" style={{ backgroundColor: '#8c52fe' }} href="#contained-buttons">
                                                VER CONVENIOS
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />
                <br />
            </Box>
            <TerminoPag />
        </>
    )
}

export default Servicios