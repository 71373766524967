import { db } from "../../firebase"; // Asegúrate de que la importación sea correcta
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

import CryptoJS from 'crypto-js';

export const obtenerDatosDeProvedores = (
  counter,
  fechaInicio,
  fechaTermino,
  estados,
  updateData
) => {
  const clientesObligatorios = [
    "SE",
    "Despegar",
    "Particulares",
    "Chile-Pasajes",
    "SPA",
    "KPS",
  ];
  const unsubscribes = [];
  let resultsPerClient = {}; // Almacena los resultados por cliente

  const sortVuelos = (vuelos) => {
    return vuelos.sort((a, b) => {
      const fechaA = new Date(`${a.Fecha}T${a.Hora}`);
      const fechaB = new Date(`${b.Fecha}T${b.Hora}`);
      return fechaA - fechaB;
    });
  };

  try {
    clientesObligatorios.forEach((cliente) => {
      const clientRef = collection(db, cliente);
      const q = query(clientRef, where("Datos.counter", "==", counter));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        let clientResults = [];

        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            const data = doc.data();
            let vuelos = data.Viaje || [];
            vuelos = sortVuelos(vuelos); // Ordenar los vuelos aquí por fecha y hora

            const filteredViajes = vuelos
              .filter((vuelo) => {
                const fechaVuelo = new Date(vuelo.Fecha);
                const estadoVuelo = vuelo.Estado;
                return (
                  fechaVuelo >= new Date(fechaInicio) &&
                  fechaVuelo <= new Date(fechaTermino) &&
                  estados.includes(estadoVuelo)
                );
              })
              .map((viaje) => ({
                idd: viaje.idd,
                Origen: viaje.Origen,
                Destino: viaje.Destino,
                Fecha: viaje.Fecha,
                Hora: viaje.Hora,
                PaxR: viaje.PaxR,
                VehiculoExtra: viaje.VehiculoExtra,
                VehiculoNoShow: viaje.VehiculoNoShow,
                Estado: viaje.Estado,
                DatosProveedor: {
                  FechaProvee: viaje.DatosProveedor?.FechaProvee || "",
                  HoraProvee: viaje.DatosProveedor?.HoraProvee || "",
                  StatusFechaHora: viaje.DatosProveedor?.StatusFechaHora || "",
                  VehiculoExtra: viaje.DatosProveedor?.VehiculoExtra || "",
                  ComentarioExtra: viaje.DatosProveedor?.ComentarioExtra || "",
                  StatusExtra: viaje.DatosProveedor?.StatusExtra || "",
                  VehiculoNoShow: viaje.DatosProveedor?.VehiculoNoShow || "",
                  ComentarioNoShow:
                  viaje.DatosProveedor?.ComentarioNoShow || "",
                  StatusNoShow: viaje.DatosProveedor?.StatusNoShow || "",
                  PaxTrasladados: viaje.DatosProveedor?.PaxTrasladados || "",
                  NombrePax: viaje.DatosProveedor?.NombrePax || "",
                  StatusPax: viaje.DatosProveedor?.StatusPax || "",
                  TipoDeVehiculo: viaje.DatosProveedor?.TipoDeVehiculo || "",
                  StatusVehiculo: viaje.DatosProveedor?.StatusVehiculo || "",
                },
              }));

            if (filteredViajes.length > 0) {
              clientResults.push({
                id: doc.id,
                Datos: {
                  aerolinea: data.Datos?.aerolinea || "",
                  aeropuerto: data.Datos?.aeropuerto || "",
                  cDistritos: data.Datos?.cDistritos || "",
                  nVuelo: data.Datos?.nVuelo || "",
                  nrt: data.Datos?.nrt || "",
                  pax: data.Datos?.pax || "",
                  selectedOption: data.Datos?.selectedOption || "",
                  fechaCounterIngresada: data.Datos?.fechaCounterIngresada || "",
                },
                Viaje: filteredViajes,
                DatosProveedor: data.DatosProveedor,
                DatosCliente: data.DatosCliente,
              });
            }
          });
        }

        resultsPerClient[cliente] = clientResults;

        // Combina los resultados de todos los clientes
        const allResults = Object.values(resultsPerClient).flat();

        // Ordena todos los viajes por la fecha y la hora del más próximo al más lejano
        const sortedResults = allResults.sort((a, b) => {
          const viajeA = a.Viaje[0]; // Asume que el primer viaje es el más próximo después de filtrar y ordenar
          const viajeB = b.Viaje[0];
          const fechaHoraA = new Date(`${viajeA.Fecha}T${viajeA.Hora}`);
          const fechaHoraB = new Date(`${viajeB.Fecha}T${viajeB.Hora}`);
          return fechaHoraA - fechaHoraB;
        });

        // Actualiza la UI con los resultados ordenados
        updateData([...sortedResults]);
      });

      unsubscribes.push(unsubscribe);
    });

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  } catch (err) {
    console.error("Error fetching data:", err);
    return [];
  }
};

export const EditarSolicitudProveedor = async (cliente, idViaje, viaje, proveedorData) => {
  try {
    const documentReference = doc(db, cliente, idViaje);

    // Obtener el documento actual en Firestore
    const documentSnapshot = await getDoc(documentReference);
    const datosExistentes = documentSnapshot.data() || {};

    // Obtener la matriz Viaje existente
    const viajeExistente = datosExistentes.Viaje || [];

    // Encontrar el índice del elemento que coincide con el 'viaje' (idd) proporcionado
    const indiceElemento = viajeExistente.findIndex(v => v.idd === viaje);

    if (indiceElemento !== -1) {
      // Actualizar el viaje en el índice encontrado
      viajeExistente[indiceElemento] = {
        ...viajeExistente[indiceElemento],
        DatosProveedor: {
          ...viajeExistente[indiceElemento].DatosProveedor, // Mantén cualquier dato existente en DatosProveedor

          FechaProvee: proveedorData.FechaProvee || viajeExistente[indiceElemento].DatosProveedor?.FechaProvee || "",
          HoraProvee: proveedorData.HoraProvee || viajeExistente[indiceElemento].DatosProveedor?.HoraProvee || "",
          StatusFechaHora: proveedorData.StatusFechaHora || viajeExistente[indiceElemento].DatosProveedor?.StatusFechaHora || "",
          UltimaActualizacionFechaHora: proveedorData.UltimaActualizacionFechaHora || viajeExistente[indiceElemento].DatosProveedor?.UltimaActualizacionFechaHora || "",

          VehiculoExtra: proveedorData.VehiculoExtra || viajeExistente[indiceElemento].DatosProveedor?.VehiculoExtra || "",
          ComentarioExtra: proveedorData.ComentarioExtra || viajeExistente[indiceElemento].DatosProveedor?.ComentarioExtra || "",
          StatusExtra: proveedorData.StatusExtra || viajeExistente[indiceElemento].DatosProveedor?.StatusExtra || "",
          UltimaActualizacionExtra: proveedorData.UltimaActualizacionExtra || viajeExistente[indiceElemento].DatosProveedor?.UltimaActualizacionExtra || "",

          VehiculoNoShow: proveedorData.VehiculoNoShow || viajeExistente[indiceElemento].DatosProveedor?.VehiculoNoShow || "",
          ComentarioNoShow: proveedorData.ComentarioNoShow || viajeExistente[indiceElemento].DatosProveedor?.ComentarioNoShow || "",
          StatusNoShow: proveedorData.StatusNoShow || viajeExistente[indiceElemento].DatosProveedor?.StatusNoShow || "",
          UltimaActualizacionNoShow: proveedorData.UltimaActualizacionNoShow || viajeExistente[indiceElemento].DatosProveedor?.UltimaActualizacionNoShow || "",

          PaxTrasladados: proveedorData.PaxTrasladados || viajeExistente[indiceElemento].DatosProveedor?.PaxTrasladados || "",
          NombrePax: proveedorData.NombrePax || viajeExistente[indiceElemento].DatosProveedor?.NombrePax || "",
          StatusPax: proveedorData.StatusPax || viajeExistente[indiceElemento].DatosProveedor?.StatusPax || "",
          UltimaActualizacionPax: proveedorData.UltimaActualizacionPax || viajeExistente[indiceElemento].DatosProveedor?.UltimaActualizacionPax || "",

          TipoDeVehiculo: proveedorData.TipoDeVehiculo || viajeExistente[indiceElemento].DatosProveedor?.TipoDeVehiculo || "",
          StatusVehiculo: proveedorData.StatusVehiculo || viajeExistente[indiceElemento].DatosProveedor?.StatusVehiculo || "",
          UltimaActualizacionVehiculo: proveedorData.UltimaActualizacionVehiculo || viajeExistente[indiceElemento].DatosProveedor?.UltimaActualizacionVehiculo || "",
        }
      };

      // Realizar la actualización en Firestore
      await updateDoc(documentReference, {
        Viaje: viajeExistente,
        updatedAt: serverTimestamp(),
      });

      console.log("Datos actualizados en Firestore:", { Viaje: viajeExistente });
    } else {
      console.error("Viaje con el idd proporcionado no encontrado.");
    }
  } catch (error) {
    console.error('Error actualizando el documento:', error);
  }
};

export const AgregarSolicitudProveedor = async (selectedOption, nrt, Viaje, idd) => {
  const documentReference = doc(db, selectedOption, nrt);

  // Obtener el documento actual en Firestore
  const documentSnapshot = await getDoc(documentReference);
  const datosExistentes = documentSnapshot.data() || {};

  // Obtener la matriz Viaje existente
  const viajeExistente = datosExistentes.Viaje || [];

  // Verificar si el objeto Viaje tiene datos válidos antes de proceder
  const viajeValido = Viaje && Viaje.idd && Viaje.Origen && Viaje.Destino && Viaje.Fecha && Viaje.Hora;

  if (viajeValido) {
      // Encontrar el índice del elemento que coincide con el idd proporcionado
      const indiceElemento = viajeExistente.findIndex((viaje) => viaje.idd === idd);

      console.log("Índice del elemento:", indiceElemento);

      if (indiceElemento !== -1) {
          // Reemplazar el elemento existente con el nuevo Viaje
          viajeExistente.splice(indiceElemento, 1, Viaje);
          console.log("Elemento reemplazado:", Viaje);
      } else {
          // Agregar el nuevo elemento al array si no existe
          viajeExistente.push(Viaje);
          console.log("Nuevo elemento agregado:", Viaje);
      }
  } else {
      console.warn("El objeto Viaje no es válido, no se realizará ninguna actualización o inserción.");
  }

  // Combinar los nuevos datos con los existentes
  const nuevosDatos = {
      Viaje: viajeExistente,
      updatedAt: serverTimestamp(),  // Añadir la marca de tiempo de la actualización
  };

 
  // Realizar la actualización en Firestore
  await updateDoc(documentReference, nuevosDatos);

  console.log("Datos actualizados en Firestore:", nuevosDatos);
};

export const AuthenticacionProveedor = async (username, password) => {
  try {
    // Buscar el usuario en Firestore por el username
    const q = query(collection(db, "UsuariosProveedor"), where("username", "==", username));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      let isAuthenticated = false;
      let counter = "";
      let firstname = "";
      let status = "";

      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

        if (hashedPassword === userData.password) {
          isAuthenticated = true;
          counter = userData.counter;
          firstname = userData.firstname;
          status = userData.status;
        }
      });

      return { isAuthenticated, counter, firstname, status };
    } else {
      return { isAuthenticated: false, counter: null, status: null };
    }
  } catch (error) {
    console.error("Error al autenticar usuario: ", error);
    throw new Error("Error en la autenticación");
  }
};