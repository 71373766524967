import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import NavbarL from '../Loyouts/Navbar';
import fondoo from "./../Imagenes/Fondoo.jpg";
import error from "./../Imagenes/Error.png";
import TerminoPag from '../Termino de pagina/TerminoPag';

const Error = () => {
  const [mostrarContenido, setMostrarContenido] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMostrarContenido(true);
    }, 1000); // Se cambió a 1 segundo para la demostración

    return () => clearTimeout(timer); // Limpieza si el componente se desmonta
  }, []);

  const containerStylee = {
    background: `url('${fondoo}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '40vh',
  };

  // Código para la animación de carga
  const carga = (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', // O ajusta según necesidad
    }}>
        <div style={{
            border: '16px solid #f3f3f3', // Color de fondo
            borderTop: '16px solid #3498db', // Color del borde
            borderRadius: '50%',
            width: '120px',
            height: '120px',
            animation: 'spin 2s linear infinite',
        }} />
    </div>
  );

  return (
    <>
      {mostrarContenido ? (
        <>
          <NavbarL />
          <Box>
            <Box style={containerStylee}>
              <br />
              <Container maxWidth="xl">
                <Box sx={{
                  marginTop: 3.4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                  <img src={error} alt="error" width="200px" />
                  <Typography component="h1" variant="h4" gutterBottom color="error" fontWeight="bold">
                    Error en el pago
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Ha ocurrido un error con tu pago. Por favor, intenta nuevamente o comunícate con soporte si el problema persiste.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      bgcolor: '#8c52fe',
                      color: 'white',
                      padding: '10px',
                      borderRadius: '5px',
                    }}
                    // Asume que tienes una función para llevar al usuario a otra página o estado
                    onClick={() => {/* Navega a donde necesites */}}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      Volver al inicio
                    </Typography>
                  </Button>
                </Box>
              </Container>
              <br /><br /><br />
            </Box>
          </Box>
          <TerminoPag />
        </>
      ) : (
        carga // Muestra la animación de carga si mostrarContenido es false
      )}
      <style>{`
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
      `}</style>
    </>
  );
}

export default Error;
