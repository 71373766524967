import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Modal,
  Typography,
  Button,
  TextField,
  Paper,
  Container,
  Snackbar,
  Alert,
} from "@mui/material";
import { grey } from '@mui/material/colors';
import { EditarSolicitudProveedor } from "../../../APIS/apiProveedor";

const AgregarNoShowProveedor = ({ open, setOpen, folio, Idd }) => {
    
  // Solicitud
  const [nrt, setNRT] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  // Viaje (estado temporal)
  const [vehiculoNoShowTemp, setVehiculoNoShowTemp] = useState("");
  const [comentarioNoShowTemp, setComentarioNoShowTemp] = useState("");
  
  // Viaje (estado guardado)
  const [VehiculoNoShow, setVehiculoNoShow] = useState("");
  const [ComentarioNoShow, setComentarioNoShow] = useState("");
  const [UltimaActualizacionNoShow, setUltimaActualizacionNoShow] = useState("");

  // Otros estados
  const [isEditing, setIsEditing] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

  // Inicialización de datos al abrir el modal
  useEffect(() => {
    setNRT(folio.Datos.nrt);
    setSelectedOption(folio.Datos.selectedOption);
    if (open && folio && folio.Viaje) {
      const viajeEncontrado = folio.Viaje.find((viaje) => viaje.idd === Idd);
      if (viajeEncontrado && viajeEncontrado.DatosProveedor) {
        setVehiculoNoShowTemp(viajeEncontrado.DatosProveedor.VehiculoNoShow || "");
        setComentarioNoShowTemp(viajeEncontrado.DatosProveedor.ComentarioNoShow || "");
      }

      const storedFirstname = localStorage.getItem("firstname");
      if (storedFirstname) {
        const currentTime = new Date().toLocaleTimeString(); // Obtiene la hora actual
        const message = `Última actualización realizada por ${storedFirstname} a las ${currentTime}`;
        setUltimaActualizacionNoShow(message);
      }
    }
  }, [open, folio, Idd]);

  // Función para guardar datos en la base de datos
  const GuardarDatos = async () => {
    if (vehiculoNoShowTemp && comentarioNoShowTemp) {
      try {
        const proveedorData = {
          VehiculoNoShow: vehiculoNoShowTemp,
          ComentarioNoShow: comentarioNoShowTemp,
          StatusNoShow: "En espera",
          UltimaActualizacionNoShow: UltimaActualizacionNoShow,
        };

        await EditarSolicitudProveedor(
          selectedOption,
          nrt,
          Idd,
          proveedorData
        );

        // Actualiza los estados guardados después de guardar en la base de datos
        setVehiculoNoShow(vehiculoNoShowTemp);
        setComentarioNoShow(comentarioNoShowTemp);
        setMostrarAlertaExito(true);
        setIsEditing(false); // Desbloquear sincronización
        setOpen(false);
      } catch (error) {
        console.error('Error saving data:', error);
        setMostrarAlerta(true);
      }
    } else {
      setMostrarAlerta(true);
    }
  };

  // Maneja el estado de edición temporal
  const handleVehiculoNoShowChange = (value) => {
    setVehiculoNoShowTemp(value);
    setIsEditing(true); // Activa el estado de edición
  };

  const handleComentarioNoShowChange = (value) => {
    setComentarioNoShowTemp(value);
    setIsEditing(true); // Activa el estado de edición
  };

  // Manejo del cierre del modal con confirmación
  const handleClose = () => {
    if (isEditing) {
      if (window.confirm("Tienes cambios sin guardar. ¿Quieres salir sin guardar?")) {
        setIsEditing(false);
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            p: 3,
            flexGrow: 1,
            width: "70%",
            maxWidth: "500px",
            maxHeight: "80vh",
            overflowY: "auto",
            backgroundColor: "#white",
            elevation: 24,
            borderRadius: "30px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Container maxWidth="xxl">
            <Box component="form" noValidate autoComplete="">
              <Typography
                style={{
                  width: '100%',
                  textAlign: "center",
                  backgroundColor: "rgb(17, 25, 42)",
                  color: "white",
                  borderRadius: "30px",
                }}
                variant="h6"
                fontWeight="bold"
              >
                NO SHOW
              </Typography>
              <br />
              <Box component="form" noValidate autoComplete="">
                <Grid item xs={12} sm={6} md={12} container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
                      id="outlined-required"
                      type="number"
                      label="No Show"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={vehiculoNoShowTemp}
                      onChange={(e) => handleVehiculoNoShowChange(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
                      id="outlined-required"
                      type="text"
                      label="Comentario"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={comentarioNoShowTemp}
                      onChange={(e) => handleComentarioNoShowChange(e.target.value)}
                      multiline
                      maxRows={10}
                    />
                  </Grid>
                </Grid>
              </Box>
              <p />
              <Grid item xs={12} sm={6} md={12} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{
                      bgcolor: "#a5d6a7",
                      fontWeight: "bold",
                      color: "green",
                      width: "100%",
                    }}
                    onClick={GuardarDatos}
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{
                      bgcolor: "#f8bbd0",
                      fontWeight: "bold",
                      color: "red",
                      width: "100%",
                    }}
                    onClick={handleClose}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Paper>
      </Modal>
      <Snackbar
        open={mostrarAlerta}
        autoHideDuration={3000}
        onClose={() => setMostrarAlerta(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          Por favor, complete todos los campos obligatorios.
        </Alert>
      </Snackbar>
      <Snackbar
        open={mostrarAlertaExito}
        autoHideDuration={3000}
        onClose={() => setMostrarAlertaExito(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          ¡Guardado con éxito! , Orden {nrt}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AgregarNoShowProveedor;
