import React, { useEffect, useState,useRef } from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import FloatingWhatsAppButton from "./wsp";
import {
  Box,
  Typography,
  TextField,
  Paper,
  Button,
  Grid,
  styled,
  FormControlLabel,
  Switch,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";

import { grey } from '@mui/material/colors';

import { Await, useLocation,useNavigate  } from "react-router-dom";



import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GuardarDatosPaginaWeb, ObtenerTodaslasSolicitudPasajeros } from "../../APIS/api";

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          // Escalar el tamaño del switch
          transform: 'scale(1.5)',
        },
        track: {
          // Establece el color de fondo por defecto para cuando el switch está "off"
          backgroundColor: 'red', // Color rojo cuando está "off"
          opacity: 1,
        },
        thumb: {
          color: '#fff', // Color del thumb
        },
        switchBase: {
          // Estilos para cuando el switch está activo
          '&.Mui-checked': {
            color: '#fff', // Color del thumb cuando está activo

            // Color del track cuando el switch está activo
            '+ .MuiSwitch-track': {
              backgroundColor: 'green', // Color verde cuando está "on"
              opacity: 1, // Opacidad completa
            },
          },
        },
      }
    }
  }
});

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#bdbdbd",
    borderWidth: 1
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd",
    borderWidth: 1
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd"
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "red"
  }
});

const InformacionPasajeros = () => {

  const [nSolicitud, setNSolicitud] = useState(null);
  const [nombreApellido, setNombreApellido] = useState("");
  const [horarioRegreso, setHorarioRegreso] = useState("");
  const [hotel, setHotel] = useState("");
  const [nContacto, setNContacto] = useState("");
  const [correo, setCorreo] = useState("");
  const [existingIds, setExistingIds] = useState([]);

  const [sufijo, setSufijo] = useState(0);

  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

  useEffect(() => {
    if (!nSolicitud) {
      const unsubscribe = ObtenerTodaslasSolicitudPasajeros("Pasajeros", (ID, ids) => {
        // setNSolicitud(`${ID.toString()}`);
        setExistingIds(ids);
        setSufijo(0); // Resetear el sufijo cuando se obtiene un nuevo ID
      });

      return () => unsubscribe();
    }
  }, [nSolicitud]);

  const EnviarDatos = async () => {
    const Datos = {
      nombreApellido: nombreApellido,
      nContacto: nContacto,
      hotel: hotel,
      horarioRegreso: horarioRegreso,
      correo: correo,
    };

    if (!nSolicitud || !nombreApellido) {
      setMostrarAlerta(true);
    } else {
      let uniqueSolicitud = nSolicitud;
      let currentSufijo = 0;

      // Encontrar el siguiente sufijo disponible
      while (existingIds.includes(uniqueSolicitud)) {
        currentSufijo += 1;
        uniqueSolicitud = `${nSolicitud} - ${currentSufijo}`;
      }

      await GuardarDatosPaginaWeb(uniqueSolicitud, Datos);
      setMostrarAlertaExito(true);
      setExistingIds([...existingIds, uniqueSolicitud]); // Actualizar los IDs existentes
      setSufijo(currentSufijo + 1); // Incrementar el sufijo para la próxima solicitud
      setNSolicitud("");
      setNombreApellido("");
      setHotel("");
      setHorarioRegreso("");
      setNContacto("");
      setCorreo("");
    }
  };

  return (
    <>
      <NavbarL />
      <br/>
      <Box sx={{ margin: 'auto', maxWidth: 500 }}>
      <Paper elevation={3} sx={{ padding: 3 }}>
      <Grid container justifyContent="center" alignItems="center" xs={12} md={12}>
            <Typography
              color="#8c52fe"
              fontWeight="bold"
              sx={{
                fontSize: '35px',
                textShadow: '1.5px 1.5px 1.5px #6200ea'
              }}
            >
              Validación | Validation
            </Typography>
          </Grid>
          <br/>
      <Grid item xs={6} md={3}>
        <ValidationTextField
          required
          style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
          id="outlined-required"
          type="text"
          label="N° Solicitud | N° Ticket address"
          value={nSolicitud}
          error={!nSolicitud}
          onChange={(e) => setNSolicitud(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <br/>
      <Grid item xs={6} md={3}>
        <ValidationTextField
          required
          style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
          id="outlined-required"
          type="text"
          label="Nombre y Apellido | Name and Surname"
          value={nombreApellido}
          error={!nombreApellido}
          onChange={(e) => setNombreApellido(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <br/>
      <Grid item xs={6} md={3}>
        <ValidationTextField
          style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
          id="outlined-required"
          type="text"
          label="Hotel Destino | Destination Hotel (optional)"
          value={hotel}
          onChange={(e) => setHotel(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <br/>
      <Grid item xs={6} md={3}>
        <ValidationTextField
          style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
          id="outlined-required"
          type="datetime-local"
          label="Fecha y hora de regreso | Return date and time (optional)"
          value={horarioRegreso}
          onChange={(e) => setHorarioRegreso(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <br/>
      <Grid item xs={6} md={3}>
        <ValidationTextField
          style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
          id="outlined-required"
          type="text"
          label="Numero de contacto | Contact number (optional)"
          value={nContacto}
          onChange={(e) => setNContacto(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <br/>
      <Grid item xs={6} md={3}>
        <ValidationTextField
          style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
          id="outlined-required"
          type="text"
          label="Correo electronico | Email address (optional)"
          value={correo}
          onChange={(e) => setCorreo(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
        <Button variant="contained" color="primary" sx={{ width: '100%', marginTop: 3 }} onClick={EnviarDatos} >
          Enviar | Send
        </Button>
      </Paper>
    </Box>
      <br/>
      <br/>
      <br/>
      <br/>
      <Snackbar
        open={mostrarAlerta}
        autoHideDuration={3000}
        onClose={() => setMostrarAlerta(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          Por favor, complete todos los campos obligatorios.
        </Alert>
      </Snackbar>
      <Snackbar
        open={mostrarAlertaExito}
        autoHideDuration={3000}
        onClose={() => setMostrarAlertaExito(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          ¡Enviado con exito!
        </Alert>
      </Snackbar>
      <TerminoPag />
    </>
  )
}

export default InformacionPasajeros;
