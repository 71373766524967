import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007bff", // Color primario (azul)
    },
    secondary: {
      main: "#6c757d", // Color secundario (gris)
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif", // Fuente de texto predeterminada
    fontSize: 15, // Tamaño de fuente predeterminado
  },
  // Agrega más propiedades de estilo según tus necesidades
});

export default theme;