import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LockOpen } from "@mui/icons-material";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { Typography } from "@mui/material";
import { AuthenticacionProveedor } from "../../APIS/apiProveedor";
import Image from "mui-image";
import Logo from '../Imagenes/Logo_morado__icono_y_texto_abajo.png';
import logoSin from '../Imagenes/Icono_morado.png';

export function Login({ handleLogin }) {

  const paperStyle = {
    padding: '20px',
    width: '80%',
    maxWidth: '400px',
    margin: 'auto',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const btnStyle = {
    marginTop: '20px',
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      alert("Por favor, complete ambos campos");
      return;
    }

    try {
      // Lógica para verificar las credenciales del usuario
      const { isAuthenticated, counter, firstname, status } = await AuthenticacionProveedor(username, password);

      if (isAuthenticated) {
        if (status === "Autorizado") {
          console.log("Usuario autenticado:", username);
          console.log("Estado del usuario:", status);

          // Establecer el estado de autenticación y guardar en localStorage
          handleLogin(true);
          localStorage.setItem("counter", counter);
          localStorage.setItem("firstname", firstname);

          // Redirigir al portal del proveedor
          navigate("/PortalProveedor");
        } else if (status === "Bloqueado") {
          // Si el usuario está bloqueado, mostrar un mensaje
          alert("Este usuario está bloqueado. Por favor, contacte al administrador.");
        } else {
          // En caso de otro estado, manejarlo de manera adecuada
          alert("Estado del usuario desconocido. Contacte al administrador.");
        }
      } else {
        alert("Usuario y contraseña incorrecta");
      }
    } catch (error) {
      console.error(error);
      alert("Hubo un error al autenticar. Inténtalo de nuevo.");
    }
  };

  useEffect(() => {
    // Si el usuario ya está autenticado, redirigir al portal del proveedor
    const auth = localStorage.getItem('isAuthenticated');
    if (auth === 'true') {
      navigate("/PortalProveedor");
    }
  }, [navigate]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: `url(${logoSin}) center / 30%  rgba(200, 200, 200, 0.7)`,
      }}
    >
      <Box>
        <Grid container justifyContent="center" alignItems="center">
          <Paper elevation={24} style={paperStyle}>
            <Grid align="center">
              <Image width="80%" src={Logo} duration={50} />
              <br />
              <Typography variant="h7" display="block">
                Iniciar Sesión
              </Typography>
            </Grid>
            <TextField
              fullWidth
              autoFocus
              color="primary"
              margin="normal"
              variant="outlined"
              label="Usuario"
              type="Usuario"
              name="Usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              fullWidth
              color="primary"
              margin="normal"
              variant="outlined"
              label="Contraseña"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              startIcon={<LockOpen />}
              color="primary"
              variant="contained"
              style={btnStyle}
              onClick={handleSubmit}
              fullWidth
            >
              INGRESAR
            </Button>
            <Typography variant="overline" color="initial">
              {" "}Sistema de transporte Proveedor{" "}
            </Typography>
            <br />
            <Typography variant="overline" color="initial">
              {" "}Rhapcore{" "}
            </Typography>
          </Paper>
        </Grid>
        <br />
      </Box>
    </div>
  );
}
