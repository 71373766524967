import React, { useEffect, useState, useRef } from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
  Grid,
  Box,
  Card,
  IconButton,
  Typography,
  Button,
  Container,
  CardContent,
  TextField,
  Stack,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  Autocomplete,
  Snackbar,
  Alert,
  CardMedia,
  Paper,
} from "@mui/material";

import { useTheme } from '@mui/material/styles';
import Image from 'mui-image';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import webpay from "./../Imagenes/webpay-logo-1.png";
import Tour1 from "./../Imagenes/Tour1.png"
import Tour2 from "./../Imagenes/Tour2.png"
import Tour3 from "./../Imagenes/Tour3.png"



import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import PropTypes from 'prop-types';
import FloatingWhatsAppButton from "./wsp";
import { GuardarDatosPaginaWeb, ObtenerTodaslasSolicitud, ObtenerDatosTarifasIDComuna, ObtenerDatosTarifasIDComunaValores } from "../../APIS/api";
import { crearTransaccion } from "../../APIS/apiTransbank";
import { v4 as uuidv4 } from 'uuid';

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#bdbdbd",
    borderWidth: 1
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 1
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd",
    borderWidth: 1
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd"
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "red"
  }
});

const Tours = ({ datos, handleData }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [id, setID] = useState("");
  const sessionId = uuidv4();

  const [origen, setOrigen] = useState("");
  const [destino, setDestino] = useState("");

  const [horaRecogida, setHoraRecogida] = useState("");
  const [fechaRecogida, setFechaRecogida] = useState("");
  const [pax, setPax] = useState("");
  const [vehiculo, setVehiculo] = useState("");

  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [comunaOrigen, setComunaOrigen] = useState("");
  const [comunaDestino, setComunaDestino] = useState("");
  const [comunasL, setComunasL] = useState([]);
  const [tarifaOrigen, setTarifaOrigen] = useState([]);
  const [tarifaDestino, setTarifaDestino] = useState([]);
  const [total, setTotal] = useState(0);

  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const stepss = ['Paso 1 de 4', 'Paso 2 de 4', 'Paso 3 de 4', 'Paso 4 de 4'];
  const stepnumber = activeStep + 0;

  useEffect(() => {
    if (datos && datos.origen && origen !== datos.origen) {
      setOrigen(datos.origen);
      setDestino(datos.destino);
      setFechaRecogida(datos.fechaRecogida);
      setPax(datos.pax);
      setHoraRecogida(datos.horaRecogida);
    }
  }, [datos, origen]);

  const VehiculosChile = [
    { id: 1, label: 'VAN' },
    { id: 2, label: 'SUV' },
  ];

  const VehiculosPeru = [
    { id: 1, label: 'VAN' },
    { id: 2, label: 'SUV' },
    { id: 3, label: 'MINI BUS' },
    { id: 4, label: 'BUS' },
  ];

  useEffect(() => {
    const cargarDatosComuna = async () => {
      const datosComuna = await ObtenerDatosTarifasIDComuna();
      const comunas = datosComuna.map(({ comuna }) => comuna);
      setComunasL(comunas);
    };
    cargarDatosComuna();
  }, []);

  useEffect(() => {
    const cargarDatosValores = async (comunaOrigen) => {
      const datosValoresOrigen = await ObtenerDatosTarifasIDComunaValores(comunaOrigen);
      const valoresOrigen = datosValoresOrigen.map(({ opaca }) => ({ opaca }));
      if (valoresOrigen[0]?.opaca?.oAlta) {
        setTarifaOrigen(valoresOrigen[0].opaca.oAlta);
      }
    };
    cargarDatosValores(comunaOrigen);
  }, [comunaOrigen]);

  useEffect(() => {
    const cargarDatosValores = async (comunaDestino) => {
      const datosValoresDestino = await ObtenerDatosTarifasIDComunaValores(comunaDestino);
      const valoreDestinos = datosValoresDestino.map(({ opaca }) => ({ opaca }));
      if (valoreDestinos[0]?.opaca?.oAlta) {
        setTarifaDestino(valoreDestinos[0].opaca.oAlta);
      }
    };
    cargarDatosValores(comunaDestino);
  }, [comunaDestino]);

  useEffect(() => {
    if (stepnumber > 2) {
      if (!comunaOrigen || !comunaDestino) {
        setTotal(0);
        return;
      }
      const total = (parseInt(tarifaOrigen) + parseInt(tarifaDestino));
      const valorFinal = Math.round(total / 2);
      setTotal(valorFinal);
    }
  }, [stepnumber]);

  const DatosCliente = {
    nombre,
    telefono,
    correo,
    mensaje,
  }

  const Viaje = {
    origen,
    comunaOrigen,
    destino,
    comunaDestino,
    horaRecogida,
    fechaRecogida,
    vehiculo,
    pax,
    total
  }

  const Datos = {
    Viaje,
    DatosCliente,
  }


  const handleWhatsappTour1 = () => {
    window.open('https://api.whatsapp.com/send?phone=56932603104&text=Estoy interesado en realizar una reserva Clásica en Viña del Mar. Me gustaría obtener más información sobre la disponibilidad, tarifas y servicios incluidos para la fecha.', '_blank');
  };

  const handleWhatsappTour2 = () => {
    window.open('https://api.whatsapp.com/send?phone=56932603104&text=Estoy interesado en realizar una reserva Premium en Viña del Mar. Me gustaría obtener más información sobre la disponibilidad, tarifas y servicios incluidos para la fecha.', '_blank');
  };

  const handleWhatsappTour3 = () => {
    window.open('https://api.whatsapp.com/send?phone=56932603104&text=Estoy interesado en realizar una reserva Exclusiva en Viña del Mar. Me gustaría obtener más información sobre la disponibilidad, tarifas y servicios incluidos para la fecha.', '_blank');
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    if (!stepss) {
      // console.log("No hay tarjeta seleccionada");
    } else {
      const unsubscribe = ObtenerTodaslasSolicitud("PaginaWeb", (ID) => {
        setID(`WEB-${ID.toString()}`);
      });
      return () => unsubscribe();
    }
  }, [stepss]);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient(95deg, #8c52fe 0%, #8c52fe 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient(95deg, #8c52fe 0%, #8c52fe 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage: 'linear-gradient(95deg, #8c52fe 0%, #8c52fe 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage: 'linear-gradient(95deg, #8c52fe 0%, #8c52fe 100%)',
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <AddLocationAltOutlinedIcon />,
      2: <CalendarMonthOutlinedIcon />,
      3: <PeopleAltOutlinedIcon />,
      4: <NoCrashIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const steps = ['Origen/Destino', 'Fecha/Vehiculo', 'Datos personales', 'Confirmación/Pago'];

  // useEffect(() => {
  //   const contar = async () => {
  //     const newID = await ObtenerDatosPaginaWeb();
  //     setID(newID);
  //   };
  //   contar();
  // }, [open]);


  // console.log(id, "ID");

  const mostrarAlertaFaltaInformacion = () => {
    setMostrarAlerta(true);
  };

  const mostrarAlertaGuardadoExito = () => {
    setMostrarAlertaExito(true);
  };

  useEffect(() => {
    if (pax > 0 && vehiculo) {
      if (vehiculo === "VAN") {
        const gruposDeVAN = Math.ceil(pax / 7);
        setTotal(total * gruposDeVAN);
      } else if (vehiculo === "SUV") {
        const gruposDeSUV = Math.ceil(pax / 3);
        setTotal(total * gruposDeSUV);
      }
    }
  }, [pax, vehiculo]);



  const GuardarDatosPagina = async () => {
    if (
      origen &&
      destino &&
      comunaOrigen &&
      comunaDestino &&
      fechaRecogida &&
      horaRecogida &&
      vehiculo &&
      nombre &&
      telefono &&
      correo
    ) {
      await GuardarDatosPaginaWeb(id, Datos);
      mostrarAlertaGuardadoExito();
      setOrigen("");
      setDestino("");
      setComunaOrigen("");
      setComunaDestino("");
      setFechaRecogida("");
      setHoraRecogida("");
      setVehiculo("");
      setPax("");
      setNombre("");
      setTelefono("");
      setCorreo("");
      setMensaje("");
      setActiveStep(0);
      return true; // Datos guardados con éxito
    } else {
      mostrarAlertaFaltaInformacion();
      return false; // Falta información
    }
  };

  const iniciarTransaccion = async () => {
    const buyOrder = `${id}-${Date.now()}`;
    const returnUrl = "https://www.transitchile.cl/Proceso";
    const errorUrl = "https://www.transitchile.cl/Error"; // URL para casos de error
    const value = total

    try {
      const data = await crearTransaccion(value, sessionId, buyOrder, returnUrl, errorUrl);
      if (data && data.url && data.token) {
        window.location.href = data.url + "?token_ws=" + data.token;
      } else {
        // Redirección al error URL si la respuesta no incluye la URL y token esperados
        window.location.href = errorUrl;
      }
    } catch (error) {
      //console.error("Error al iniciar la transacción: ", error.message);
      // Redirección al error URL en caso de excepción
      window.location.href = errorUrl;
    }
  };


  return (
    <>
      <NavbarL />
      <FloatingWhatsAppButton />
      <br />
      <Grid container xs={12} md={12}>
        <Grid item container justifyContent="center" alignItems="center">
          <Grid item justifyContent="center" alignItems="center" container xs={10} md={10}>
            <Typography
              fontWeight="bold"
              sx={{
                fontSize: '40px',
              }}
            >
              NUESTRAS ALIANZAS
            </Typography>
          </Grid>
          <Grid item justifyContent="center" alignItems="center" container xs={10} md={10}>
            <Typography
              sx={{
                fontSize: '25px',
              }}
            >
              NUESTRAS ALIANZAS
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <br/>
      <Grid container >
        <Grid container justifyContent="center" alignItems="center" spacing={6}>
          <Grid item xs={10} md={3.5} >
            <Paper
              elevation={24}
              sx={{
                height: "auto",
                borderRadius: 5,
                bgcolor: (theme) => theme.palette.background.default,
                p: 3,
              }}
            >
              <Typography
                color="#8c52fe"
                fontWeight="bold"
                textAlign="center"
                variant="h5"
              >
                TOUR 1
              </Typography>
              <Typography
                color="#8c52fe"
                textAlign="center"
                variant="h6"
              >
                Experiencia Clásica de Viña
              </Typography>
              <Typography
                textAlign="left"
                variant="body1"
              >
                - Degustación de 3 copas de vino
                <br />
                - Una copa de regalo
                <br />
                - Recorrido por la viña
                <br />
                - Duración: 70 minutos
                <br />
                - Transporte ida y vuelta para 6 personas
                <br />
                - Capacidad: Máximo 25 personas por grupo
              </Typography>
              <br />
              <CardMedia component="img" height="auto" image={Tour1} sx={{  borderRadius: '25px'}} />
              <br />
              <br />
              <Typography
                textAlign="left"
                variant="body1"
              >
                - Horarios: Primer tour a las 9:30 y último tour a las 17:00
                <br />
                - Precio:
                <br />
                - Acceso al tour completo por persona $34.990 CLP
                <br />
                - Transporte ida y vuelta $129,990 CLP (capacidad para 6 personas por pago único)
              </Typography>
              <br />
              <Button
                size="medium"
                color="primary"
                sx={{
                  width: '100%',
                  bgcolor: '#8c52fe',
                  color: 'white',
                  padding: '10px',
                  fontWeight: "bold",
                  borderRadius: '30px',
                }}
                onClick={handleWhatsappTour1}
              >
                PROGRAMAR
              </Button>
              <br />
            </Paper>
          </Grid>
          <Grid item xs={10} md={3.5} >
            <Paper
              elevation={24}
              sx={{
                height: "auto",
                borderRadius: 5,
                bgcolor: (theme) => theme.palette.background.default,
                p: 3,
              }}
            >
              <Typography
                color="#8c52fe"
                fontWeight="bold"
                textAlign="center"
                variant="h5"
              >
                TOUR 2
              </Typography>
              <Typography
                color="#8c52fe"
                textAlign="center"
                variant="h6"
              >
                Experiencia Premium de Viña
              </Typography>
              <Typography
                textAlign="left"
                variant="body1"
              >
                - Degustación de 7 copas de vino.
                <br />
                - Una copa de regalo.
                <br />
                - Recorrido por la viña.
                <br />
                - Tabla de quesos.
                <br />
                - Duración: 100 minutos.
                <br />
                - Transporte ida y vuelta para 6 personas.
                <br />
                - Capacidad: Máximo 25 personas por grupo.
              </Typography>
              <br />
              <CardMedia component="img" height="auto" image={Tour2} sx={{  borderRadius: '25px'}} />
              <br />
              <br />
              <Typography
                textAlign="left"
                variant="body1"
              >
                - Horarios: Primer tour a las 10:00 y último tour a las 16:10
                <br />
                - Precio:
                <br />
                - Acceso al tour completo por persona 58.990 CLP
                <br />
                - Transporte ida y vuelta 129.990 CLP (capacidad para 6 personas por pago único)
              </Typography>
              <br />
              <Button
                size="medium"
                color="primary"
                sx={{
                  width: '100%',
                  bgcolor: '#8c52fe',
                  color: 'white',
                  padding: '10px',
                  fontWeight: "bold",
                  borderRadius: '30px',
                }}
                onClick={handleWhatsappTour2}
              >
                PROGRAMAR
              </Button>
              <br />
            </Paper>
          </Grid>
          <Grid item xs={10} md={3.5} >
            <Paper
              elevation={24}
              sx={{
                height: "auto",
                borderRadius: 5,
                bgcolor: (theme) => theme.palette.background.default,
                p: 3,
              }}
            >
              <Typography
                color="#8c52fe"
                fontWeight="bold"
                textAlign="center"
                variant="h5"
              >
                TOUR 3
              </Typography>
              <Typography
                color="#8c52fe"
                textAlign="center"
                variant="h6"
              >
                Experiencia Exclusiva de Viña
              </Typography>
              <Typography
                textAlign="left"
                variant="body1"
              >
                - Degustación de 9 copas de vino
                <br />
                - Una copa de regalo
                <br />
                - Recorrido por la viña
                <br />
                - Tabla de quesos
                <br />
                - Duración: 100 minutos
                <br />
                - Transporte ida y vuelta para 6 personas.
                <br />
                - Capacidad: Máximo 16 personas por grupo
                <br />
              </Typography>
              <br />
              <CardMedia component="img" height="auto" image={Tour3} sx={{  borderRadius: '25px'}} />
              <br />
              <br />
              <Typography
                textAlign="left"
                variant="body1"
              >
                - Horarios: Primer tour a las 10:30 y último tour a las 13:30
                <br />
                - Precio:
                <br />
                - Acceso al tour completo por persona $85.990
                <br />
                - Transporte ida y vuelta $129.990 (capacidad para 6 personas por pago único)
              </Typography>
              <br />
              <Button
                size="medium"
                color="primary"
                sx={{
                  width: '100%',
                  bgcolor: '#8c52fe',
                  color: 'white',
                  padding: '10px',
                  fontWeight: "bold",
                  borderRadius: '30px',
                }}
                onClick={handleWhatsappTour3}
              >
                Programar
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <TerminoPag />
    </>
  )
}

export default Tours;
