import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import Home from './components/PaginaWeb/Pagina de inicio/Home';
import QuienesSomos from './components/PaginaWeb/Pagina de inicio/Quienes somos';
import Reservas from './components/PaginaWeb/Pagina de inicio/Reservas';
import Servicios from './components/PaginaWeb/Pagina de inicio/Servicios';
import Confirmacion from './components/PaginaWeb/Paginas Transbank/Confirmacion';
import Error from './components/PaginaWeb/Paginas Transbank/Error';
import Proceso from './components/PaginaWeb/Paginas Transbank/Proceso';
import Tours from './components/PaginaWeb/Pagina de inicio/Tours';
import ReactPixel from 'react-facebook-pixel';
import Page404 from './components/PaginaWeb/PageError';
import Verificacion from './components/PaginaWeb/Pagina de inicio/Verificacion';
import Contacto from './components/PaginaWeb/Pagina de inicio/Contacto';
import InformacionPasajeros from './components/PaginaWeb/Pagina de inicio/InformacionPasajeros';
import PersonalAcreditado from './components/PaginaWeb/Pagina de inicio/PersonalAcreditado';
import Qwerty from "./components/PaginaWeb/Pagina de inicio/Qwerty";
import PanelDeControlProvee from './components/PaginaWeb/Pagina de inicio/PanelDeControlProvee';
import { Login } from './components/PaginaWeb/Pagina de inicio/Login';
import { ProtectedRoute } from './context/ProtectedRoute';

function App() {
  const [datos, setDatos] = useState(null);

  useEffect(() => {
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init('1193397671640698', null, options);
    ReactPixel.pageView();
  }, []);

  const handleData = (data, callback = () => {}) => {
    setDatos(data);
    callback(data);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Revisar si ya hay una sesión activa (si está en localStorage)
    const auth = localStorage.getItem('isAuthenticated');
    if (auth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (status) => {
    setIsAuthenticated(status);
    localStorage.setItem('isAuthenticated', status); // Guardar en localStorage
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Borrar del localStorage
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home handleData={handleData} />} />
        <Route path="/Quienes-Somos" element={<QuienesSomos />} />
        <Route path="/Reservas" element={<Reservas datos={datos} handleData={handleData} />} />
        <Route path="/VerificacionPersonalAcreditado" element={<PersonalAcreditado datos={datos} handleData={handleData} />} />
        <Route path="/Servicios" element={<Servicios />} />
        <Route path="/Contacto" element={<Contacto />} />
        <Route path="/Tours" element={<Tours />} />
        <Route path="/Confirmacion" element={<Confirmacion />} />
        <Route path="/Verificacion" element={<Verificacion />} />
        <Route path="/InformacionPasajeros" element={<InformacionPasajeros />} />
        <Route path="/Error" element={<Error />} />
        <Route path="/Proceso" element={<Proceso />} />
        <Route path="/qwertyuioipñlkjjhgfdfsaazxccvbb" element={<Qwerty />} />
        <Route path="*" element={<Page404 />} />
        <Route path="/PortalProveedor" element={
          <ProtectedRoute>
            <PanelDeControlProvee />
          </ProtectedRoute>
        } />
        <Route path="/login" element={<Login handleLogin={handleLogin} />} />
      </Routes>
    </Router>
  );
}

ReactPixel.init('1193397671640698');
ReactPixel.pageView();

export default App;

// actualizado 2024-10-16