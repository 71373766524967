import { createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { db, storage, auth } from "../../firebase";

import {
    addDoc,
    collection,
    setDoc,
    getDocs,
    doc,
    updateDoc,
    deleteDoc,
    where,
    query,
    getDoc,
    orderBy,
    limit,
    serverTimestamp,
    onSnapshot,
    Timestamp,
} from "firebase/firestore";

import {
    ref,
    uploadBytes,
    listAll,
    getDownloadURL,
    deleteObject,
    getStorage,
} from 'firebase/storage';



export const ObtenerTodaslasSolicitud = (selectedOption, callback) => {
    const ingresoRef = collection(db, selectedOption);
    const unsubscribe = onSnapshot(ingresoRef, (querySnapshot) => {
        let maxId = 0;

        querySnapshot.forEach((doc) => {
            const idParts = doc.id.split('-');
            const numericPart = parseInt(idParts[1], 10);
            if (!isNaN(numericPart) && numericPart > maxId) {
                maxId = numericPart;
            }
        });

        const newId = maxId + 1;
        callback(newId);

        // console.log("Entro", newId);
    });

    return unsubscribe;
};

export const ObtenerTodaslasSolicitudPasajeros = (selectedOption, callback) => {
    const ingresoRef = collection(db, selectedOption);
    const unsubscribe = onSnapshot(ingresoRef, (querySnapshot) => {
        let maxId = 0;
        const ids = [];

        querySnapshot.forEach((doc) => {
            ids.push(doc.id);
            const idParts = doc.id.split('-');
            const numericPart = parseInt(idParts[0], 10);
            if (!isNaN(numericPart) && numericPart > maxId) {
                maxId = numericPart;
            }
        });

        const newId = maxId + 1;
        callback(newId, ids);
    });

    return unsubscribe;
};




// CONTENEDOR SOLICITUDES
//////////////////////////////////////////////////////////////////////////////////

/** Obtener
 * Obtener datos de la DB
 * collection(db, "NOMBRE_DB")
 * id: doc.id // Id del documento
 * DB | Contratos | ID(nContrato) | Datos: zona
 * 
 */
export const obtenerDatosPorZona = async (zona) => {
    try {
        const ingresoRef = collection(db, "Contratos");
        const consulta = query(ingresoRef, where("Datos.zonas", "==", zona));
        const querySnapshot = await getDocs(consulta);
        const result = [];
        querySnapshot.forEach((doc) => {
            result.push({
                tecContrato: doc.data().Datos.tecContrato,
            });
        });
        // console.log("Documentos filtrados:", result);
        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const ActualizarSolicitV2 = async (selectedOption, nrt, Datos, Viaje, DatosCliente, idd) => {
    const documentReference = doc(db, selectedOption, nrt);

    // Obtener el documento actual en Firestore
    const documentSnapshot = await getDoc(documentReference);
    const datosExistentes = documentSnapshot.data() || {};

    // Obtener la matriz Viaje existente
    const viajeExistente = datosExistentes.Viaje || [];

    // Encontrar el índice del elemento que coincide con el idd proporcionado
    const indiceElemento = viajeExistente.findIndex((viaje) => viaje.idd === idd);

    console.log("Índice del elemento:", indiceElemento);

    if (indiceElemento !== -1) {
        // Reemplazar el elemento existente con el nuevo Viaje
        viajeExistente.splice(indiceElemento, 1, Viaje);
        console.log("Elemento reemplazado:", Viaje);
    } else {
        // Agregar el nuevo elemento al array si no existe
        viajeExistente.push(Viaje);
        console.log("Nuevo elemento agregado:", Viaje);
    }

    // Combinar los nuevos datos con los existentes
    const nuevosDatos = {
        Datos: { ...datosExistentes.Datos, ...Datos },
        Viaje: viajeExistente,
        DatosCliente: { ...datosExistentes.DatosCliente, ...DatosCliente },
    };

    // Realizar la actualización en Firestore
    await updateDoc(documentReference, nuevosDatos);

    console.log("Datos actualizados en Firestore:", nuevosDatos);
};
//////////////////////////////////////////////////////////////////
// CONTENEDOR SOLICITUDES

export const ObtenerDatosDeSolicitudes = async (cliente, fechaInicio, fechaTermino, estados) => {
    try {
        const ingresoRef = collection(db, cliente);
        const querySnapshot = await getDocs(ingresoRef);
        const result = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();

            let ESTADORECO = data.Reco?.rEstado;
            let ESTADOZARPE = data.Zarpe?.zEstado;
            let ESTADOZRP = data.ZRP?.zrpEstado;

            if (
                (estados.length === 0 ||
                    estados.includes(ESTADORECO) ||
                    estados.includes(ESTADOZARPE) ||
                    estados.includes(ESTADOZRP)) &&
                (fechaDentroDeRango(data.Reco?.rFecha, fechaInicio, fechaTermino) ||
                    fechaDentroDeRango(data.Zarpe?.zFecha, fechaInicio, fechaTermino) ||
                    fechaDentroDeRango(data.ZRP?.zrpFecha, fechaInicio, fechaTermino)
                )
            ) {
                result.push({
                    ...data,
                    id: doc.id,
                });
            }
        });

        return result;
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const ObtenerDatosDeSolicitudesv2 = async (clientes, fechaInicio, fechaTermino, estados) => {
    try {
        const result = [];

        for (const cliente of clientes) {
            const ingresoRef = collection(db, cliente);
            const querySnapshot = await getDocs(ingresoRef);

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const vuelos = data.Viaje || [];

                vuelos.forEach((vuelo) => {
                    const fechaVuelo = vuelo.Fecha;
                    const estadoVuelo = vuelo.Estado;

                    if (fechaVuelo >= fechaInicio && fechaVuelo <= fechaTermino && estados.includes(estadoVuelo)) {
                        const newData = {
                            ...data,
                            id: doc.id,
                        };

                        const exists = result.some(item => item.id === newData.id);

                        if (!exists) {
                            result.push(newData);
                        }
                    }
                });
            });

            onSnapshot(ingresoRef, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const doc = change.doc;
                    const data = doc.data();
                    const vuelos = data.Viaje || [];

                    vuelos.forEach((vuelo) => {
                        const fechaVuelo = vuelo.Fecha;
                        const estadoVuelo = vuelo.Estado;

                        if (fechaVuelo >= fechaInicio && fechaVuelo <= fechaTermino && estados.includes(estadoVuelo)) {
                            const newData = {
                                ...data,
                                id: doc.id,
                            };

                            if (change.type === "added") {
                                const exists = result.some(item => item.id === newData.id);

                                if (!exists) {
                                    result.push(newData);
                                }
                            } else if (change.type === "modified") {
                                const index = result.findIndex(item => item.id === newData.id);

                                if (index !== -1) {
                                    result[index] = newData;
                                }
                            } else if (change.type === "removed") {
                                const index = result.findIndex(item => item.id === newData.id);

                                if (index !== -1) {
                                    result.splice(index, 1);
                                }
                            }
                        }
                    });
                });
            });
        }

        return result;
    } catch (err) {
        console.error(err);
        return [];
    }
};



export const ObtenerDatosDeSolicitudesWebv2 = async () => {
    try {
        const ingresoRef = collection(db, "PaginaWeb");
        const querySnapshot = await getDocs(ingresoRef);
        const result = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            console.log(data)

            result.push({
                id: doc.id,
                ...data,
            });
        });
        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const ObtenerDatosDeAsistenciaTabla = async () => {
    try {
        const ingresoRef = collection(db, "AsistenciaTabla");
        const querySnapshot = await getDocs(ingresoRef);
        const result = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            console.log(data)

            result.push({
                id: doc.id,
                ...data,
            });
        });
        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
};




export const ObtenerDatosDeSolicitudesProvee = async (cliente, fechaInicio, fechaTermino, estados, counter) => {
    try {
        const ingresoRef = collection(db, cliente);
        const querySnapshot = await getDocs(ingresoRef);

        const result = [];

        console.log(counter);

        querySnapshot.forEach((doc) => {
            const data = doc.data();

            let ESTADORECO = data.Reco?.rEstado;
            let ESTADOZARPE = data.Zarpe?.zEstado;
            let ESTADOZRP = data.ZRP?.zrpEstado;

            if (
                (estados.length === 0 || estados.includes(ESTADORECO) || estados.includes(ESTADOZARPE) || estados.includes(ESTADOZRP)) &&
                (fechaDentroDeRango(data.Reco?.rFecha, fechaInicio, fechaTermino) || fechaDentroDeRango(data.ZRP?.zrpFecha, fechaInicio, fechaTermino)) &&
                (counter === undefined || counter === data.Datos?.counter)
            ) {
                const item = {
                    ...data,
                    id: doc.id,
                    counter: data.Datos?.counter || 0,
                };

                result.push(item);
            }
        });

        return result;
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const ObtenerDatosDeSolicitudesChat = async (selectedOption) => {
    try {
        const ingresoRef = collection(db, selectedOption);
        const querySnapshot = await getDocs(ingresoRef);
        const result = [];
        querySnapshot.forEach((doc) => {
            result.push({
                id: doc.id
            });
        });

        result.sort((a, b) => {
            const idA = parseInt(a.id.split('-')[1]);
            const idB = parseInt(b.id.split('-')[1]);

            return idB - idA;
        });

        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
};


const fechaDentroDeRango = (fecha, inicio, fin) => {
    const fechaInicio = new Date(inicio);
    const fechaFin = new Date(fin);
    const fechaSolicitud = new Date(fecha);

    return fechaSolicitud >= fechaInicio && fechaSolicitud <= fechaFin;
};

//////////////////////////////////////////////////////////////////////////////////
// INICIO DE CONTENEDOR DESTINOS

/** Guardar
 * Almacenar en la DB
 * nContrato = ID // Del documento
 * Datos = [] // Informacion
 * DB | Contratos | NumeroDeContrato | Datos: nContrato,rutProveedor,productor,superCon,tecContrato,predio,comunaPredio,tipoContrato,zonas)
 *                                  
 */
export const GuardarDatosDestinos = async (id, Datos) => {
    setDoc(doc(db, `Destinos`, id), { Datos });
}

/** Actualizar
 * Actualizar DB
 * id = ID // Del documento
 * Datos = [] // Informacion
 */
export const ActualizarDestinos = async (id, Datos) => {
    await updateDoc(doc(db, "Destinos", id), { Datos })
}


/** Eliminar
 * Eliminar DB
 * id = ID // Del documento
 */
export const EliminarContenedorDestinos = async (id) => {
    await deleteDoc(doc(db, "Destinos", id))
}

/** Obtener
 * Obtener datos de la DB
 * collection(db, "NOMBRE_DB")
 * id: doc.id // Id del documento
 * DB | Texturas | ID(ID) | Datos: Todos
 * 
 */
export const ObtenerDatosDestinos = async () => {
    try {
        const ingresoRef = collection(db, "Destinos");
        const querySnapshot = await getDocs(ingresoRef);
        const result = [];
        querySnapshot.forEach((doc) => {
            result.push({
                ...doc.data(),
                id: doc.id
            });
        });
        // console.log("Todos los datos de Texturas:",result)
        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
}

/** Obtener
 * Obtener datos de la DB
 * collection(db, "NOMBRE_DB")
 * id: doc.id // Id del documento
 * DB | Texturas | ID(ID) | Datos: Todos
 * 
 */

export const ObtenerDatosDestinosID = async () => {
    try {
        const ingresoRef = collection(db, "Destinos");
        const querySnapshot = await getDocs(ingresoRef);
        const result = [];
        querySnapshot.forEach((doc) => {
            result.push({
                id: doc.id
            });
        });
        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
}

// FIN DE CONTENEDOR DESTINOS
//////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////
// INICIO DE CONTENEDOR TARIFAS DESPEGAR

/** Guardar
 * Almacenar en la DB
 * nContrato = ID // Del documento
 * Datos = [] // Informacion
 * DB | Contratos | NumeroDeContrato | Datos: nContrato,rutProveedor,productor,superCon,tecContrato,predio,comunaPredio,tipoContrato,zonas)
 *                                  
 */
export const GuardarDatosTarifas = async (id, Datos) => {
    setDoc(doc(db, `TarifasDespegar`, id), { Datos });
}

/** Actualizar
 * Actualizar DB
 * id = ID // Del documento
 * Datos = [] // Informacion
 */
export const ActualizarTarifas = async (id, Datos) => {
    await updateDoc(doc(db, "TarifasDespegar", id), { Datos })
}


/** Eliminar
 * Eliminar DB
 * id = ID // Del documento
 */
export const EliminarContenedorTarifas = async (id) => {
    await deleteDoc(doc(db, "TarifasDespegar", id))
}

/** Obtener
 * Obtener datos de la DB
 * collection(db, "NOMBRE_DB")
 * id: doc.id // Id del documento
 * DB | Texturas | ID(ID) | Datos: Todos
 * 
 */
export const ObtenerDatosTarifas = async () => {
    try {
        const ingresoRef = collection(db, "TarifasDespegar");
        const querySnapshot = await getDocs(ingresoRef);
        const result = [];
        querySnapshot.forEach((doc) => {
            result.push({
                ...doc.data(),
                id: doc.id
            });
        });
        // console.log("Todos los datos de Texturas:",result)
        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
}

/** Obtener
 * Obtener datos de la DB
 * collection(db, "NOMBRE_DB")
 * id: doc.id // Id del documento
 * DB | Texturas | ID(ID) | Datos: Todos
 * 
 */

export const ObtenerDatosTarifasID = async () => {
    try {
        const ingresoRef = collection(db, "TarifasDespegar");
        const querySnapshot = await getDocs(ingresoRef);
        const result = [];
        querySnapshot.forEach((doc) => {
            result.push({
                id: doc.id
            });
        });
        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
}

/** Obtener
 * Obtener datos de la DB
 * collection(db, "NOMBRE_DB")
 * id: doc.id // Id del documento
 * DB | Texturas | ID(ID) | Datos: Todos
 * 
 */
export const ObtenerDatosTarifasIDComuna = async () => {
    try {
        const ingresoRef = collection(db, "TarifasDespegar");
        const querySnapshot = await getDocs(ingresoRef);
        const result = [];
        querySnapshot.forEach((doc) => {
            result.push({
                id: doc.id,
                comuna: doc.data().Datos.comuna,
            });
        });
        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const ObtenerDatosTarifasIDComunaValores = async (comuna) => {
    try {
        const ingresoRef = collection(db, "TarifasDespegar");
        const querySnapshot = await getDocs(ingresoRef);
        const result = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Verificar si el documento corresponde a la comuna deseada (usando el ID)
            if (data.Datos.comuna === comuna) {
                result.push({
                    opaca: data.Datos.Opaca,
                });
            }
        });

        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
}

// Pagina WEB

export const GuardarDatosPaginaWeb = async (id, Datos) => {
    await setDoc(doc(db, `Pasajeros`, id), { Datos });
}

// Pagina WEB Proveedores

export const GuardarDatosProveedor = async (id, Datos) => {
    setDoc(doc(db, `SolicitudesProveedor`, id), { Datos });
}

export const VerificarTicket = async (ticket) => {
    try {
        const docRef = doc(db, `SolicitudesProveedor`, ticket);
        const docSnap = await getDoc(docRef);

        return docSnap.exists();
    } catch (error) {
        console.error("Error al verificar el ticket:", error);
        return false;
    }
};

export const VerificarPersonalAcreditado = async (nombre) => {
    try {
        const docRef = doc(db, "PersonalAcreditado", nombre);
        const docSnap = await getDoc(docRef);

        return docSnap.exists() && docSnap.data().Datos.state === true;
    } catch (error) {
        console.error("Error al verificar el ticket:", error);
        return false;
    }
};

export const ObtenerImagenPersonalAcreditado = async (nombre) => {
    const storage = getStorage();
    try {
        const imageRef = ref(storage, `PersonalAcreditado/${nombre}.png`);
        const url = await getDownloadURL(imageRef);
        return url;
    } catch (error) {
        console.error("Error al obtener la imagen:", error);
        return null;
    }
};
