import React, { useEffect,useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import NavbarL from '../Loyouts/Navbar';
import fondoo from "./../Imagenes/Fondoo.jpg";
import TerminoPag from '../Termino de pagina/TerminoPag';

const Confirmacion = () => {

  const [mostrarContenido, setMostrarContenido] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMostrarContenido(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const containerStylee = {
    background: `url('${fondoo}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '40vh',
  };

  const carga = (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    }}>
        <div style={{
            border: '16px solid #f3f3f3',
            borderTop: '16px solid #3498db',
            borderRadius: '50%',
            width: '120px',
            height: '120px',
            animation: 'spin 2s linear infinite',
        }} />
    </div>
  );
  
  return (
    <>
    {mostrarContenido ? (
        <>
    <NavbarL/>
    <Box>
      <Box style={containerStylee} >
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
        <Container maxWidth="xl">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            
          >
        <Typography component="h1" variant="h4" gutterBottom fontWeight="bold" 
        sx={{ color: '#8c52fe' }}>
          Pago exitoso
        </Typography>
        <Typography variant="body1" gutterBottom>
          Pronto se comunicarán con usted para coordinar de mejor manera el servicio.
        </Typography>
        <Button
          variant="contained"
          sx={{
            mt: 3,
            bgcolor: '#8c52fe',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
          }}
          // Asume que tienes una función para llevar al usuario a otra página o estado
          onClick={() => {/* Navega a donde necesites */}}
        >
            <Typography variant="body2" fontWeight="bold">
                Volver al inicio
            </Typography>
        </Button>
      </Box>
    </Container>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </Box>
    </Box>
    <TerminoPag/>
    </>
      ) : (
        carga // Muestra la animación de carga si mostrarContenido es false
      )}
      <style>{`
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
      `}
      </style>
    </>
  );
}

export default Confirmacion;
