import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Modal,
  Typography,
  Button,
  TextField,
  Paper,
  Container,
  Snackbar,
  Alert,
  styled,
} from "@mui/material";
import { grey } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';
import { AgregarSolicitudProveedor } from "../../../APIS/apiProveedor";

// CSS de campos de validación
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#bdbdbd",
    borderWidth: 1,
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd",
    borderWidth: 1,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd",
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "red",
  },
});

const AgregarViajeProveedor = ({ open, setOpen, folio }) => {
  // Estados temporales
  const [origenTemp, setOrigenTemp] = useState("");
  const [destinoTemp, setDestinoTemp] = useState("");
  const [fechaTemp, setFechaTemp] = useState("");
  const [horaTemp, setHoraTemp] = useState("");

  // Otros estados
  const [idd, setIdd] = useState(uuidv4());
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (folio) {
      // Inicializa los datos al abrir el modal
      setIdd(uuidv4());
      setOrigenTemp(folio.Datos.origen || "");
      setDestinoTemp(folio.Datos.destino || "");
      setFechaTemp(folio.Datos.fecha || "");
      setHoraTemp(folio.Datos.hora || "");
    }
  }, [folio, open]);

  // Función para guardar datos en la base de datos
  const GuardarDatos = async () => {
    if (origenTemp && destinoTemp && fechaTemp && horaTemp) {
      const Viaje = {
        idd,
        Origen: origenTemp,
        Destino: destinoTemp,
        Fecha: fechaTemp,
        Hora: horaTemp,
        Estado: "Por Validar",
      };

      try {
        await AgregarSolicitudProveedor(folio.Datos.selectedOption, folio.id, Viaje, idd);
        setMostrarAlertaExito(true);
        setIsEditing(false);
        setOpen(false);
      } catch (error) {
        console.error('Error saving data:', error);
        setMostrarAlerta(true);
      }
    } else {
      setMostrarAlerta(true);
    }
  };

  // Manejo de edición
  const handleFieldChange = (setter, value) => {
    setter(value);
    setIsEditing(true); // Activa el estado de edición
  };

  // Confirmación al cerrar
  const handleClose = () => {
    if (isEditing) {
      if (window.confirm("Tienes cambios sin guardar. ¿Quieres salir sin guardar?")) {
        setIsEditing(false);
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            p: 3,
            flexGrow: 1,
            width: "70%",
            maxWidth: "500px",
            maxHeight: "80vh",
            overflowY: "auto",
            backgroundColor: "#white",
            elevation: 24,
            borderRadius: "30px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Container maxWidth="xxl">
            <Box component="form" noValidate autoComplete="">
              <Typography
                style={{
                  width: '100%',
                  textAlign: "center",
                  backgroundColor: "rgb(17, 25, 42)",
                  color: "white",
                  borderRadius: "30px",
                }}
                variant="h6"
                fontWeight="bold"
              >
                AGREGAR VIAJE
              </Typography>
              <br />
              <Box component="form" noValidate autoComplete="">
                <Grid item xs={12} sm={6} md={12} container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <ValidationTextField
                      label="Origen"
                      variant="outlined"
                      fullWidth
                      value={origenTemp}
                      error={!origenTemp}
                      onChange={(e) => handleFieldChange(setOrigenTemp, e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ValidationTextField
                      label="Destino"
                      variant="outlined"
                      fullWidth
                      value={destinoTemp}
                      error={!destinoTemp}
                      onChange={(e) => handleFieldChange(setDestinoTemp, e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
                      type="date"
                      label="Fecha"
                      InputLabelProps={{ shrink: true }}
                      value={fechaTemp}
                      onChange={(e) => handleFieldChange(setFechaTemp, e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
                      type="time"
                      label="Hora"
                      InputLabelProps={{ shrink: true }}
                      value={horaTemp}
                      onChange={(e) => handleFieldChange(setHoraTemp, e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <p />
              <Grid item xs={12} sm={6} md={12} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{
                      bgcolor: "#a5d6a7",
                      fontWeight: "bold",
                      color: "green",
                      width: "100%",
                    }}
                    onClick={GuardarDatos}
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{
                      bgcolor: "#f8bbd0",
                      fontWeight: "bold",
                      color: "red",
                      width: "100%",
                    }}
                    onClick={handleClose}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Paper>
      </Modal>
      <Snackbar
        open={mostrarAlerta}
        autoHideDuration={3000}
        onClose={() => setMostrarAlerta(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          Por favor, complete todos los campos obligatorios.
        </Alert>
      </Snackbar>
      <Snackbar
        open={mostrarAlertaExito}
        autoHideDuration={3000}
        onClose={() => setMostrarAlertaExito(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          ¡Guardado con éxito! , Orden {folio.id}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AgregarViajeProveedor;
