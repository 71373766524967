export const crearTransaccion = async (total, sessionId, buyOrder, returnUrl,errorUrl) => {
  try {
    const apiUrl = `https://us-central1-transit-399da.cloudfunctions.net/crearTransaccion`;
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: total,
        sessionId,
        buyOrder,
        returnUrl,
        errorUrl
      })
    });

    if (!response.ok) {
      // Si el servidor responde con un código de error, manejar adecuadamente
      // console.error('Respuesta de error del servidor:', response.statusText);
      return null;
    }

    // Asumimos que la respuesta es correcta y intentamos analizar el JSON
    try {
      const data = await response.json();
      // Procesar la respuesta JSON aquí
      return data;
    } catch (error) {
      // Captura el error si el cuerpo de la respuesta no es un JSON válido
      // console.error('La respuesta no es un JSON válido:', error);
      return null;
    }
  } catch (error) {
    // Captura errores de red o fallos al realizar la solicitud
    // console.error('Excepción al iniciar la transacción con Transbank:', error);
    return null;
  }
};
