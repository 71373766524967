import React from 'react';

import { Drawer, Link,List, ListItem,IconButton, ListItemIcon,Box, ListItemText,useMediaQuery, Divider,Container,Grid, Typography } from '@mui/material';
import Image from 'mui-image';
import logo from "./../Imagenes/Logo.svg";
import fondo from "./../Imagenes/Santiago.jpg";
import { Call, Facebook, Instagram, Mail, WhatsApp } from '@mui/icons-material';
import playstore from "./../Imagenes/Google_Play_Store.svg.png";
import appstore from "./../Imagenes/App_Store.svg.png";
import ContactsIcon from '@mui/icons-material/Contacts';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Link as RouterLink } from 'react-router-dom';

const TerminoPag = () => {

  const containerStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${fondo}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '10vh',
  }

  const handleWhatsappClick = () => {
    // Reemplaza el número con tu número de WhatsApp
    window.open('https://api.whatsapp.com/send?phone=56932603104&text=', '_blank');
  };

  const handleFaceClick = () => {
    window.open('https://www.facebook.com/profile.php?id=61551504677912&mibextid=WC7FNe', '_blank');
  };

  const handleInstaClick = () => {
    window.open('https://www.instagram.com/somostransit/', '_blank');
  };



  return (
    <>
    <Box style={containerStyle} >
        <Container maxWidth="xl">
          <Grid container spacing={3} xs={12} md={12} justifyContent="center" alignItems="center">
            <Grid item container xs={12} md={3}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid container justifyContent="center" alignItems="center" xs={12} md={12}>
                  <Image width={200} height={200} src={logo} duration={500} />
                </Grid>
              <Grid item container xs={12} md={12}>
                <br/>
              </Grid>
              <Grid item container xs={12} md={12}>
                <Grid item container justifyContent="center" alignItems="center">
                  <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                    <IconButton
                      sx={{
                        bgcolor: "#8c52fe",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "100%", 
                        border: "1px solid #8c52fe",
                        transition: "background-color 0.3s ease-in-out",
                        "&:hover": {
                          bgcolor: "#7e57c2",
                        },
                      }}
                      aria-label="Eliminar"
                      color="error"
                      size="medium"
                      onClick={handleWhatsappClick}
                    >
                      <WhatsApp fontSize="medium" />
                    </IconButton>
                  </Grid>
                  <Grid item justifyContent="center" alignItems="center" container xs={4} md={4}>
                    <IconButton
                      sx={{
                        bgcolor: "#8c52fe",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "100%",
                        border: "1px solid #8c52fe",
                        transition: "background-color 0.3s ease-in-out",
                        "&:hover": {
                          bgcolor: "#7e57c2",
                        },
                      }}
                      aria-label="Eliminar"
                      color="error"
                      onClick={handleFaceClick}
                      size="medium"
                    >
                      <Facebook fontSize="medium" />
                    </IconButton>
                  </Grid>
                  <Grid item justifyContent="center" alignItems="center"container xs={4} md={4}>
                    <IconButton
                      sx={{
                        bgcolor: "#8c52fe",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "100%",
                        border: "1px solid #8c52fe",
                        transition: "background-color 0.3s ease-in-out",
                        "&:hover": {
                          bgcolor: "#7e57c2",
                        },
                      }}
                      aria-label="Eliminar"
                      color="error"
                      onClick={handleInstaClick}
                      size="medium"
                    >
                      <Instagram fontSize="medium" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} md={12}>
                <br/>
              </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={12} md={3}>
              <Grid container justifyContent="center" alignItems="center">
                <Typography variant="h6" fontWeight="bold" color="white"
                sx={{ textShadow: '2px 2px 2px black'}}
                >Nosotros</Typography>
                <Grid item container xs={12} md={12}>
                  <br/>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} md={12}>
                  <Link component={RouterLink} to="/Quienes-Somos">
                        <Typography
                          variant="body2"
                          color="white"
                          fontWeight="bold"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '17px',
                          }}
                        >
                        <ContactsIcon 
                        sx={{
                          bgcolor: "#8c52fe",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "100%", 
                          border: "3px solid #8c52fe",
                          marginRight: '5px',
                          }} 
                        />
                        Quiénes somos
                        </Typography>
                      </Link>
                  </Grid>
                  <Grid item container xs={12} md={12}>
                    <br/>
                  </Grid>
                  <Grid item container justifyContent="center" alignItems="center" xs={12} md={12}>
                  <Link component={RouterLink} to="/Quienes-Somos">
                    <Typography
                      variant="body2"
                      color="white"
                      fontWeight="bold"
                      sx={{
                        fontSize: '17px',
                      }}
                    >
                      <LiveHelpIcon
                        sx={{
                          bgcolor: "#8c52fe",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "100%",
                          border: "3px solid #8c52fe",
                          verticalAlign: 'middle',
                          marginRight: '5px',
                        }}
                      />
                      Covenio de uso
                      {/* <LiveHelpIcon
                        sx={{
                          bgcolor: "#8c52fe",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "100%",
                          border: "3px solid #8c52fe",
                          verticalAlign: 'middle',
                          marginLeft: '5px',
                        }}
                      /> */}
                    </Typography> 
                  </Link>
                  </Grid>
                  <Grid item container xs={12} md={12}>
              <br/>
                  </Grid>
                <Grid container justifyContent="center" alignItems="center" xs={12} md={12}>
                </Grid>
              </Grid>
            </Grid>


            <Grid item container xs={12} md={3}>
              <Grid container justifyContent="center" alignItems="center">
                <Typography variant="h6" fontWeight="bold" color="white"
                sx={{ textShadow: '2px 2px 2px black'}}
                >Contacto</Typography>
                <Grid item container xs={12} md={12}>
                  <br/>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} md={12}>
                      <Link href="tel:+56932603104">
                        <Typography
                          variant="body2"
                          color="white"
                          fontWeight="bold"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '17px',
                          }}
                        >
                        <Call 
                        sx={{
                          bgcolor: "#8c52fe",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "100%", 
                          border: "3px solid #8c52fe",
                          marginRight: '5px',
                          }} 
                        />
                        +56 9 3260 3104 
                        </Typography>
                      </Link>
                  </Grid>
                  <Grid item container xs={12} md={12}>
                    <br/>
                  </Grid>
                  <Grid item container justifyContent="center" alignItems="center" xs={12} md={12}>
                  <Link href="mailto:Reservas@transitchile.cl">
                    <Typography
                      variant="body2"
                      color="white"
                      fontWeight="bold"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '17px',
                      }}
                    >
                    <Mail 
                    sx={{
                      bgcolor: "#8c52fe",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "100%", 
                      border: "3px solid #8c52fe",
                      marginRight: '5px',
                      }} 
                    />
                    Reservas@transitchile.cl
                    {/* <Mail 
                    sx={{
                      bgcolor: "#8c52fe",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "100%", 
                      border: "3px solid #8c52fe",
                      marginLeft: '5px',
                      }} 
                    /> */}
                    </Typography>
                  </Link>
                  </Grid>
                  <Grid item container xs={12} md={12}>
                    <br/>
                  </Grid>
                  <Grid item container xs={12} md={12}>
              <br/>
                  </Grid>
                <Grid container justifyContent="center" alignItems="center" xs={12} md={12}>
                </Grid>
              </Grid>
            </Grid>


            <Grid item container xs={3} md={3}>
              <Grid container justifyContent="center" alignItems="center">
                <Typography variant="h6" fontWeight="bold" color="white" 
                sx={{ textShadow: '2px 2px 2px black'}} > Próximamente </Typography>
                <Grid item container xs={12} md={12}>
                  <br/>
                </Grid>
                <Grid container justifyContent="center" alignItems="center" xs={12} md={12}>
                <img 
                  src={playstore} 
                  alt="playstore" 
                  width="200px" 
                  height="auto" 
                  style={{ marginTop: '15px', marginBottom: '5px' }} // Agregar margen superior e inferior
                />
                </Grid>
                <Grid container justifyContent="center" alignItems="center" xs={12} md={12}>
                <img 
                  src={appstore} 
                  alt="appstore" 
                  width="200px" 
                  height="auto" 
                  style={{ marginTop: '15px', marginBottom: '5px' }} // Agregar margen superior e inferior
                />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={12}>
              </Grid>


          </Grid>
        </Container>
    </Box>
    <Box bgcolor="black" color="white" p={2}>
      <Container>
        <Typography variant="body2" align="center">
          Todos los derechos reservados © 2024 T&Transit
        </Typography>
      </Container>
    </Box>
    </>
  );
}

export default TerminoPag;
